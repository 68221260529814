import React from "react";
import Footer from "../../components/Footer";
import "./index.scss";
import { useSelector } from "react-redux";

const DataProtection = () => {
  let language = useSelector((state) => state.language);
  return (
    <div className="containerData">
      <div className="containerData__box">
        {language === "de" ? <h1>Datenschutz</h1> : <h1>Data Protection</h1>}
        {language === "de" ? (
          <p className="containerData__box__paragraph">
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend der gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung. Die
            Nutzung unserer Website ist in der Regel ohne Angabe
            personenbezogener Daten möglich. Soweit auf unseren Seiten
            personenbezogene Daten (beispielsweise Name, Anschrift oder
            E-Mail-Adressen) erhoben werden, erfolgt dies auf Basis Ihrer
            Einwilligung oder soweit gesetzlich zulässig. Diese Daten werden
            ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B.
            bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
            nicht möglich. <br />
            <br />
            <span>Cookies</span>
            <br />
            <br /> Die Internetseiten verwenden teilweise so genannte Cookies.
            Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten
            keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher,
            effektiver und sicherer zu machen. Cookies sind kleine Textdateien,
            die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
            Die meisten der von uns verwendeten Cookies sind so genannte
            „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch
            gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis
            Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser
            beim nächsten Besuch wiederzuerkennen. Sie können Ihren Browser so
            einstellen, dass Sie über das Setzen von Cookies informiert werden
            und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für
            bestimmte Fälle oder generell ausschließen sowie das automatische
            Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der
            Deaktivierung von Cookies kann die Funktionalität dieser Website
            eingeschränkt sein. <br />
            <br />
            <span> Server-Log-Files</span>
            <br />
            <br /> Der Provider der Seiten erhebt und speichert automatisch
            Informationen in so genannten Server-Log Files, die Ihr Browser
            automatisch an uns übermittelt. <br />
            <br />
            <span> Dies sind:</span>
            <br />
            <br /> -Browsertyp und Browserversion
            <br /> -Verwendetes Betriebssystem
            <br /> -Referrer URL
            <br /> -Hostname des zugreifenden Rechners
            <br /> -Uhrzeit der Serveranfrage <br />
            Diese Daten sind nicht bestimmten Personen zuordenbar. Eine
            Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
            vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu
            prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige
            Nutzung bekannt werden.
            <br />
            <br /> Kontaktformular Wenn Sie uns per Kontaktformular Anfragen
            zukommen lassen, werden Ihre Angaben aus dem Anfrageformular
            inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks
            Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns
            gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung
            weiter.
            <br />
            <br />
            <span> Newsletterdaten</span>
            <br />
            <br /> Mit Ihrer Einwilligung können Sie den auf der Website
            angebotenen Newsletter beziehen. Für die Anmeldung zu unserem
            Newsletter verwenden wir das sog. Double-opt-in-Verfahren. Das
            heißt, dass wir ihnen nach Ihrer Anmeldung eine E-Mail an die
            angegebene E-Mail-Adresse senden, in welcher wir Sie um Bestätigung
            bitten, dass Sie den Versand des Newsletters wünschen. Wenn Sie Ihre
            Anmeldung nicht innerhalb von 24 Stunden betätigen, werden Ihre
            Informationen gesperrt und nach einem Monat automatisch gelöscht.
            Zweck des Verfahrens ist, Ihre Anmeldung nachweisen und ggf. einen
            möglichen Missbrauch Ihrer Persönlichen Daten aufklären zu können.
            Nach Ihrer Bestätigung speichern wir Ihre E-Mail-Adresse zum Zweck
            der Zusendung des Newsletter Die erteilte Einwilligung zur
            Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum
            Versand des Newsletters können Sie jederzeit widerrufen und den
            Newsletter abbestellen, etwa durch Klick auf den in jeder
            Newsletter-E-Mail bereitgestellten Link.
            <br />
            <br />
            <span> Datenerhebung bei Visitenkarten </span>
            <br />
            <br />
            Wenn Sie uns Ihre Visitenkarte geben, werden von uns die nachfolgend
            aufgezählten persönlichen Daten von Ihnen erhoben und verarbeitet:
            Name, Vorname, Position, Firma, Telefonnummer, E-Mail, Adresse. Ihre
            Daten dienen der Kontaktaufnahme, zur Weitergabe von Informationen
            sowie der möglichen Zusammenarbeit mit m.Doc. Die Verarbeitung Ihrer
            Daten (Erhebung, Speicherung) erfolgt aufgrund ausdrücklicher
            Einwilligung des/der Betroffenen (mit Übergabe der Visitenkarte),
            mithin gemäß Art. 6 Abs. 1 Buchstabe a DSGVO. Sie kann auch zur
            Durchführung vorvertraglicher Maßnahmen erforderlich sein, die auf
            Anfrage des/der Betroffenen erfolgen, Art. 6 Abs. 1 Buchstabe b
            DSGVO. Die Verarbeitung Ihrer Daten in einem
            Kundenverwaltungsprogramm von m.Doc ist für Ausbau und Pflege des
            Partner- und Kundennetzzwerkes von m.Doc erforderlich und dient
            damit der Wahrnehmung berechtigter Interessen der Beteiligten, Art.
            6 Abs. 1 Buchstabe f DSGVO. Wir geben die Daten nicht ohne Ihre
            ausdrückliche Einwilligung weiter. Wir speichern Ihre Daten, bis der
            jeweilige Zweck entfallen ist oder Sie Ihre Einwilligung widerrufen
            haben und keine gesetzlichen Aufbewahrungsfristen entgegenstehen.
            <br />
            <br />
            <span> Google Analytics</span>
            <br />
            <br /> <span>Allgemein</span>
            <br />
            <br /> Diese Website nutzt Funktionen des Webanalysedienstes Google
            Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway
            Mountain View, CA 94043, USA. Google Analytics verwendet so genannte
            “Cookies”. Das sind Textdateien, die auf Ihrem Computergespeichert
            werden und die eine Analyse der Benutzung der Website durch Sie
            ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre
            Benutzung dieser Website werden in der Regel an einen Server von
            Google in den USA übertragen und dort gespeichert. <br />
            <br />
            <span> IP-Anonymisierung</span>
            <br />
            <br />
            Wir haben auf dieser Website die Funktion IP-Anonymisierung
            aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von
            Mitgliedstaaten der Europäischen Union oder in anderen
            Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
            vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird
            die volle IP-Adresse an einen Server von Google in den USA
            übertragen und dort gekürzt. Im Auftrag des Betreibers dieser
            Website wird Google diese Informationen benutzen, um Ihre Nutzung
            der Website auszuwerten, um Reports über die Websiteaktivitäten
            zusammenzustellen und um weitere mit der Websitenutzung und der
            Internetnutzung verbundene Dienstleistungen gegenüber dem
            Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics
            von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen
            Daten von Google zusammengeführt. <br />
            <br />
            <span> Browser Plugin</span> <br />
            <br />
            Sie können die Speicherung der Cookies durch eine entsprechende
            Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
            darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
            Funktionen dieser Website vollumfänglich werden nutzen können. Sie
            können darüber hinaus die Erfassung der durch den Cookie erzeugten
            und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
            IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
            Google verhindern, indem Sie das unter dem folgenden Link verfügbare
            Browser-Plugin herunterladen und installieren:
            https://tools.google.com/dlpage/gaoptout?hl=de <br />
            <br />
            <span>Widerspruch gegen Datenerfassung</span> <br />
            <br />
            Sie können die Erfassung Ihrer Daten durch Google Analytics
            verhindern, indem Sie auf folgenden Link klicken. Es wird ein
            Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei
            zukünftigen Besuchen dieser Website verhindert
            https://tools.google.com/dlpage/gaoptout?hl=de Mehr Informationen
            zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der
            Datenschutzerklärung von Google:
            <p style={{ fontSize: "15px" }}>
              https://support.google.com/analytics/answer/6004245?hl=de
            </p>
            <br />
            <br />
            <span> Demografische Merkmale bei Google Analytics</span>
            <br />
            <br /> Diese Website nutzt die Funktion “demografische Merkmale” von
            Google Analytics. Dadurch können Berichte erstellt werden, die
            Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher
            enthalten. Diese Daten stammen aus interessenbezogener Werbung von
            Google sowie aus Besucherdaten von Drittanbietern. Diese Daten
            können keiner bestimmten Person zugeordnet werden. Sie können diese
            Funktion jederzeit über die Anzeigeneinstellungen in Ihrem
            Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch
            Google Analytics wie im Punkt “Widerspruch gegen Datenerfassung”
            dargestellt generell untersagen. Facebook-Plugins (Like-Button) Auf
            unseren Seiten sind Plugins des sozialen Netzwerks Facebook,
            Anbieter Facebook Inc., 1 Hacker Way, Menlo Park, California 94025,
            USA, integriert. Die Facebook-Plugins erkennen Sie an dem
            Facebook-Logo oder dem “Like-Button” (“Gefällt mir”) auf unserer
            Seite. Eine Übersicht über die Facebook-Plugins finden Sie hier:
            <p style={{ fontSize: "15px" }}>
              https://developers.facebook.com/docs/plugins/.
            </p>
            Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte
            Verbindung zwischen Ihrem Browser und dem Facebook-Server
            hergestellt. Facebook erhält dadurch die Information, dass Sie mit
            Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den Facebook
            “Like-Button” anklicken während Sie in Ihrem Facebook-Account
            eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem
            Facebook-Profil verlinken. Dadurch kann Facebook den Besuch unserer
            Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir
            als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten
            Daten sowie deren Nutzung durch Facebook erhalten. Weitere
            Informationen hierzu finden Sie in der Datenschutzerklärung von
            Facebook unter https://de-de.facebook.com/policy.php. Wenn Sie nicht
            wünschen, dass Facebook den Besuch unserer Seiten Ihrem
            Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem
            Facebook-Benutzerkonto aus.
            <br />
            <br /> <span>Twitter</span> <br />
            <br />
            Auf unseren Seiten sind Funktionen des Dienstes Twitter eingebunden.
            Diese Funktionen werden angeboten durch die Twitter Inc., 1355
            Market Street, Suite 900, San Francisco, CA 94103, USA. Durch das
            Benutzen von Twitter und der Funktion “Re-Tweet” werden die von
            Ihnen besuchten Websites mit Ihrem Twitter-Account verknüpft und
            anderen Nutzern bekannt gegeben. Dabei werden auch Daten an Twitter
            übertragen. Wir weisen darauf hin, dass wir als Anbieter der Seiten
            keine Kenntnis vom Inhalt der übermittelten Daten sowie deren
            Nutzung durch Twitter erhalten. Weitere Informationen hierzu finden
            Sie in der Datenschutzerklärung von Twitter unter
            https://twitter.com/privacy. Ihre Datenschutzeinstellungen bei
            Twitter können Sie in den Konto-Einstellungen unter:
            https://twitter.com/account/settings
            <br />
            <br />
            <span> Google+</span> <br />
            <br />
            Unsere Seiten nutzen Funktionen von Google+. Anbieter ist die Google
            Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043, USA.
            Erfassung und Weitergabe von Informationen: Mithilfe der
            Google+-Schaltfläche können Sie Informationen weltweit
            veröffentlichen. Über die Google+-Schaltfläche erhalten Sie und
            andere Nutzer personalisierte Inhalte von Google und unseren
            Partnern. Google speichert sowohl die Information, dass Sie für
            einen Inhalt +1 gegeben haben, als auch Informationen über die
            Seite, die Sie beim Klicken auf +1 angesehen haben. Ihre +1 können
            als Hinweise zusammen mit Ihrem Profilnamen und Ihrem Foto in
            Google-Diensten, wie etwa in Suchergebnissen oder in Ihrem
            Google-Profil, oder an anderen Stellen auf Websites und Anzeigen im
            Internet eingeblendet werden. Google zeichnet Informationen über
            Ihre +1-Aktivitäten auf, um die Google-Dienste für Sie und andere zu
            verbessern. Um die Google+-Schaltfläche verwenden zu können,
            benötigen Sie ein weltweit sichtbares, öffentliches Google-Profil,
            das zumindest den für das Profil gewählten Namen enthalten muss.
            Dieser Name wird in allen Google-Diensten verwendet. In manchen
            Fällen kann dieser Name auch einen anderen Namen ersetzen, den Sie
            beim Teilen von Inhalten über Ihr Google-Konto verwendet haben. Die
            Identität Ihres Google-Profils kann Nutzern angezeigt werden, die
            Ihre E-Mail-Adresse kennen oder über andere identifizierende
            Informationen von Ihnen verfügen. Verwendung der erfassten
            Informationen: Neben den oben erläuterten Verwendungszwecken werden
            die von Ihnen bereitgestellten Informationen gemäß den geltenden
            Google-Datenschutzbestimmungen genutzt. Google veröffentlicht
            möglicherweise zusammengefasste Statistiken über die +1-Aktivitäten
            der Nutzer bzw. gibt diese an Nutzer und Partner weiter, wie etwa
            Publisher, Inserenten oder verbundene Websites.
            <br />
            <br /> <span>Instagram</span> <br />
            <br />
            Auf unseren Seiten sind Funktionen des Dienstes Instagram
            eingebunden. Diese Funktionen werden angeboten durch die Instagram
            Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA integriert. Wenn
            Sie in Ihrem Instagram-Account eingeloggt sind können Sie durch
            Anklicken des Instagram-Buttons die Inhalte unserer Seiten mit Ihrem
            Instagram-Profil verlinken. Dadurch kann Instagram den Besuch
            unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin,
            dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
            übermittelten Daten sowie deren Nutzung durch Instagram erhalten.
            Weitere Informationen hierzu finden Sie in der Datenschutzerklärung
            von Instagram: https://instagram.com/about/legal/privacy/ <br />
            <br />
            <span> Linkedin</span>
            <br />
            <br /> Unsere Website nutzt Funktionen des Netzwerks LinkedIn.
            Anbieter ist die LinkedIn Corporation, 2029 Stierlin Court, Mountain
            View, CA 94043, USA. Bei jedem Abruf einer unserer Seiten, die
            Funktionen von LinkedIn enthält, wird eine Verbindung zu Servern von
            LinkedIn aufbaut. LinkedIn wird darüber informiert, dass Sie unsere
            Internetseiten mit Ihrer IP-Adresse besucht haben. Wenn Sie den
            “Recommend-Button” von LinkedIn anklicken und in Ihrem Account bei
            LinkedIn eingeloggt sind, ist es LinkedIn möglich, Ihren Besuch auf
            unserer Internetseite Ihnen und Ihrem Benutzerkonto zuzuordnen. Wir
            weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis
            vom Inhalt der übermittelten Daten sowie deren Nutzung durch
            LinkedIn haben. Weitere Informationen hierzu finden Sie in der
            Datenschutzerklärung von LinkedIn unter:
            https://www.linkedin.com/legal/privacy-policy <br />
            <br />
            <span>Pinterest</span>
            <br />
            <br /> Auf unserer Seite verwenden wir Social Plugins des sozialen
            Netzwerkes Pinterest, das von der Pinterest Inc., 808 Brannan Street
            San Francisco, CA 94103-490, USA (“Pinterest”) betrieben wird. Wenn
            Sie eine Seite aufrufen, die ein solches Plugin enthält, stellt Ihr
            Browser eine direkte Verbindung zu den Servern von Pinterest her.
            Das Plugin übermittelt dabei Protokolldaten an den Server von
            Pinterest in die USA. Diese Protokolldaten enthalten möglicherweise
            Ihre IP-Adresse, die Adresse der besuchten Websites, die ebenfalls
            Pinterest-Funktionen enthalten, Art und Einstellungen des Browsers,
            Datum und Zeitpunkt der Anfrage, Ihre Verwendungsweise von Pinterest
            sowie Cookies.Weitere Informationen zu Zweck, Umfang und weiterer
            Verarbeitung und Nutzung der Daten durch Pinterest sowie Ihre
            diesbezüglichen Rechte und Möglichkeiten zum Schutz Ihrer
            Privatsphäre finden Sie in den Datenschutzhinweisen von Pinterest:
            https://about.pinterest.com/de/privacy-policy <br />
            <br />
            <span> XING</span>
            <br />
            <br /> Unsere Website nutzt Funktionen des Netzwerks XING. Anbieter
            ist die XING AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland.
            Bei jedem Abruf einer unserer Seiten, die Funktionen von XING
            enthält, wird eine Verbindung zu Servern von XING hergestellt. Eine
            Speicherung von personenbezogenen Daten erfolgt dabei nach unserer
            Kenntnis nicht. Insbesondere werden keine IP-Adressen gespeichert
            oder das Nutzungsverhalten ausgewertet. Weitere Information zum
            Datenschutz und dem XING Share-Button finden Sie in der
            Datenschutzerklärung von XING unter:
            https://www.xing.com/app/share?op=data_protection <br />
            <br />
            <span> Tumblr</span>
            <br />
            <br /> Unsere Seiten nutzen Schaltflächen des Dienstes Tumblr.
            Anbieter ist die Tumblr, Inc., 35 East 21st St, 10th Floor, New
            York, NY 10010, USA. Diese Schaltflächen ermöglichen es Ihnen, einen
            Beitrag oder eine Seite bei Tumblr zu teilen oder dem Anbieter bei
            Tumblr zu folgen. Wenn Sie eine unserer Websites mit Tumblr-Button
            aufrufen, baut der Browser eine direkte Verbindung mit den Servern
            von Tumblr auf. Wir haben keinen Einfluss auf den Umfang der Daten,
            die Tumblr mit Hilfe dieses Plugins erhebt und übermittelt. Nach
            aktuellem Stand werden die IP-Adresse des Nutzers sowie die URL der
            jeweiligen Website übermittelt.Weitere Informationen hierzu finden
            sich in der Datenschutzerklärung von Tumblr unter:
            https://www.tumblr.com/policy/de/privacy . <br />
            <br />
            <span> YouTube</span> <br />
            <br />
            Unsere Website nutzt Plugins der von Google betriebenen Seite
            YouTube. Betreiber der Seiten ist die YouTube, LLC, 901 Cherry Ave.,
            San Bruno, CA 94066, USA. Wenn Sie eine unserer mit einem
            YouTube-Plugin ausgestatteten Seiten besuchen, wird eine Verbindung
            zu den Servern von YouTube hergestellt. Dabei wird dem
            YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben.
            Wenn Sie in Ihrem YouTube-Account eingeloggt sind ermöglichen Sie
            YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil
            zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
            YouTube-Account ausloggen. Weitere Informationen zum Umgang von
            Nutzerdaten finden Sie in der Datenschutzerklärung von YouTube
            unter: https://www.google.de/intl/de/policies/privacy <br />
            <br />
            <span>SSL-Verschlüsselung</span>
            <br />
            <br />
            Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen,
            die Sie an uns als Seitenbetreiber senden, eine SSL-Verschlüsselung.
            Eine verschlüsselte Verbindung erkennen Sie daran, dass die
            Adresszeile des Browsers von “http://” auf “https://” wechselt und
            an dem Schloss-Symbol in Ihrer Browserzeile Wenn die SSL
            Verschlüsselung aktiviert ist, können die Daten, die Sie an uns
            übermitteln, nicht von Dritten mitgelesen werden. <br />
            <br />
            <span>Datenerhebung bei Online-Bewerbung </span>
            <br />
            <br />
            Im Zuge Ihrer Online-Bewerbung werden von uns die nachfolgend
            aufgezählten persönlichen Bewerbungsdaten von Ihnen erhoben und
            verarbeitet: Name, Vorname, Adresse, Telefonnummer, E-Mail,
            Bewerbungsunterlagen (Bewerbungsschreiben, Lebenslauf, Zeugnisse,
            Zertifikate u.ä.). <br />
            <br />
            <span>Zweck der Datenerfassung / Weitergabe</span>
            <br />
            <br /> Die Erhebung und Verarbeitung Ihrer persönlichen
            Bewerbungsdaten erfolgt ausschließlich zweckgebunden für die
            Besetzung von Stellen innerhalb unseres Unternehmens. Ihre Daten
            werden grundsätzlich nur an die für das konkrete Bewerbungsverfahren
            zuständigen innerbetrieblichen Stellen und Fachabteilungen unseres
            Unternehmens weitergeleitet. Eine darüber hinausgehende Nutzung oder
            Weitergabe Ihrer Bewerbungsdaten an Dritte erfolgt nicht.
            Aufbewahrungsdauer der Bewerbungsdaten Eine Löschung Ihrer
            persönlichen Bewerbungsdaten erfolgt grundsätzlich automatisch sechs
            Monate nach Abschluss des Bewerbungsverfahrens bzw. unmittelbar,
            wenn wir Ihnen keine Stelle anbieten können. Dies gilt nicht, sofern
            gesetzliche Bestimmungen einer Löschung entgegenstehen, die weitere
            Speicherung zum Zwecke der Beweisführung erforderlich ist oder Sie
            einer längeren Speicherung ausdrücklich zugestimmt haben. <br />
            <br />
            <span> Speicherung für zukünftige Stellenausschreibungen</span>{" "}
            <br />
            <br />
            Sollten wir Ihnen keine aktuell zu besetzende Stelle anbieten
            können, jedoch aufgrund Ihres Profils der Ansicht sein, dass Ihre
            Bewerbung eventuell für zukünftige Stellenangebote interessant sein
            könnte, werden wir Ihre persönlichen Bewerbungsdaten zwölf Monate
            lang speichern, sofern Sie einer solchen Speicherung und Nutzung
            ausdrücklich zustimmen. <br />
            <br />
            <span> Ihre Rechte</span> <br />
            <br />
            Sie haben uns gegenüber folgende Rechte hinsichtlich der Sie
            betreffenden personenbezogenen Daten: <br />
            -Recht auf Auskunft <br />
            -Recht auf Berichtigung oder Löschung <br />
            -Recht auf Einschränkung der Verarbeitung
            <br />
            -Recht auf Widerspruch gegen die Verarbeitung <br />
            -Recht auf Datenübertragbarkeit
            <br /> Sie haben zudem das Recht, sich bei einer
            Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer
            personenbezogenen Daten durch uns zu beschweren. <br />
            <br />
            <span> Widerspruch Werbe-Mails</span>
            <br />
            <br /> Der Nutzung von im Rahmen der Impressumspflicht
            veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich
            angeforderter Werbung und Informationsmaterialien wird hiermit
            widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich
            rechtliche Schritte im Falle der unverlangten Zusendung von
            Werbeinformationen, etwa durch Spam-E-Mails, vor. <br />
            <br />
            <span> Verantwortlicher und Datenschutzbeauftragter</span>
            <br />
            <br /> Verantwortlicher ist m.Doc GmbH, Ursulaplatz 1, 50668 Köln,
            E-Mail: info@mdoc.one . Für Fragen zum Datenschutz wenden Sie sich
            bitte an unseren Datenschutzbeauftragten unter datenschutz@mdoc.one.
          </p>
        ) : (
          <p>
            The operators of these pages take the protection of your personal
            data very seriously. We treat your personal data confidentially
            confidentially and in accordance with the data protection
            regulations and this privacy policy. The use of our website is
            generally possible without personal data. As far as on our sides
            personal data (such as name, address or e-mail addresses) e-mail
            addresses) is collected, this is done on the basis of your consent
            or as far as legally permissible. This data will not be not passed
            on to third parties without your express consent. We point out that
            data transmission over the Internet (eg. communication by e-mail)
            may have security gaps. A complete protection of the data against
            access by third parties is not not possible. <br />
            <br />
            <span>Cookies</span>
            <br />
            <br /> The Internet pages partly use so-called cookies. Cookies do
            not cause any damage to your computer and do not contain any
            viruses. viruses. Cookies serve to make our offer more
            user-friendly, more effective and safer. Cookies are small text
            files that are stored on your computer and saved by your browser.
            Most of the cookies we use are so-called "session cookies". They are
            automatically deleted at the end of your visit. deleted. Other
            cookies remain stored on your terminal until you delete them. you
            delete them. These cookies allow us to recognize your browser to
            recognize your browser on your next visit. You can set your browser
            to inform you browser so that you are informed about the setting of
            cookies cookies on a case-by-case basis, to refuse the use of
            cookies for certain certain cases or generally exclude the automatic
            deletion of cookies deletion of cookies when closing the browser.
            With the the functionality of this website may be limited if cookies
            are deactivated. be limited. <br />
            <br />
            <span> Server-Log-Files</span>
            <br />
            <br /> The provider of the pages automatically collects and stores
            information in so-called server log files, which your browser
            automatically automatically transmitted to us. <br />
            <br />
            <span> These are:</span>
            <br />
            <br /> -Browser type and version
            <br /> -Operating system used
            <br /> -Referrer URL
            <br /> -hostname of the accessing computer
            <br /> -time of the server request
            <br />
            This data cannot be assigned to specific persons. A This data will
            not be merged with other data sources. made. We reserve the right to
            check this data retrospectively if we become aware of concrete
            indications of illegal use. become known to us.
            <br />
            <br /> Contact form If you send us inquiries by contact form contact
            form, your data from the inquiry form, including the contact
            including the contact data provided by you will be used for the
            purpose of processing of the request and in the event of follow-up
            questions. stored. We do not pass on this data without your consent.
            on without your consent.
            <br />
            <br />
            <span> Newsletter data</span>
            <br />
            <br /> With your consent, you can subscribe to the newsletter
            newsletter offered on the website. For the registration to our
            newsletter we use the so-called double-opt-in procedure. The means
            that after your registration, we will send you an e-mail to the
            e-mail address in which we ask you to confirm that you wish to
            receive the newsletter. that you wish to receive the newsletter. If
            you do not registration within 24 hours, your information will be
            blocked and automatically deleted information will be blocked and
            automatically deleted after one month. The purpose of this procedure
            is to verify your registration and, if necessary, to clarify a
            possible misuse of your personal data. After your confirmation, we
            store your e-mail address for the purpose of of sending you the
            newsletter The consent given for the the storage of data, the e-mail
            address and its use for sending the newsletter newsletter can be
            revoked at any time and you can unsubscribe from the unsubscribe
            from the newsletter, for example, by clicking on the link Newsletter
            e-mail provided link.
            <br />
            <br />
            <span> Business card data collection </span>
            <br />
            <br />
            When you give us your business card, we will collect from you the
            following personal data listed below will be collected from you and
            processed: Name, first name, position, company, telephone number,
            e-mail, address. Your data will be used to contact you, to pass on
            information and to as well as the possible cooperation with m.Doc.
            The processing of your data (collection, storage) is based on the
            express consent of the consent of the person(s) concerned (by
            handing over the business card), therefore in accordance with Art. 6
            para. 1 letter a DSGVO. It may also be necessary for may also be
            necessary for the implementation of pre-contractual measures that
            are request of the data subject, Art. 6 para. 1 letter b DSGVO. The
            processing of your data in a customer management program of m.Doc is
            necessary for the expansion and maintenance of the and maintenance
            of the partner and customer network of m.Doc and thus serves the and
            serves the legitimate interests of the parties involved, Art. 6
            para. 1 letter f DSGVO. We do not pass on the data without your
            express consent. We store your data until the respective purpose has
            ceased to apply or you have revoked your consent and no have revoked
            your consent and there are no legal retention periods to the
            contrary.
            <br />
            <br />
            <span> Google Analytics</span>
            <br />
            <br /> <span>General</span>
            <br />
            <br /> This website uses functions of the web analysis service
            Google Analytics. The provider is Google Inc., 1600 Amphitheatre
            Parkway Mountain View, CA 94043, USA. Google Analytics uses
            so-called "cookies". These are text files that are stored on your
            computer and that allow and which enable an analysis of your use of
            the website. enable. The information generated by the cookie about
            your your use of this website is usually transmitted to a Google
            server in the Google in the USA and stored there. <br />
            <br />
            <span> IP anonymization</span>
            <br />
            <br />
            We have activated the IP anonymization function on this website.
            function on this website. This means that your IP address will not
            be used by Google within Member States of the European Union or in
            other States party to the Agreement on the European Economic Area.
            before being transmitted to the USA. Only in exceptional cases will
            the full IP address will be transmitted to a Google server in the
            USA and and shortened there. On behalf of the operator of this
            Google will use this information for the purpose of evaluating your
            use of the website of the website, to compile reports on website
            activity and to make further website activity and to provide other
            services relating to the use of the website services related to the
            use of the website and the internet website operator. The IP address
            transmitted by your transmitted by your browser as part of Google
            Analytics will not be data from Google. <br />
            <br />
            <span> Browser Plugin</span> <br />
            <br />
            You can prevent the storage of cookies by selecting the appropriate
            your browser software; however, we would like to point out that in
            this case that in this case you may not be able to use the full
            functionality of this website. functions of this website to their
            full extent. You can also refuse the use of cookies by selecting the
            appropriate settings on your browser. your use of the website
            (including your IP address) to Google and the processing IP address)
            to Google and the processing of this data by Google. Google by
            downloading and installing the browser plugin available at the
            following link Download and install the browser plugin:
            https://tools.google.com/dlpage/gaoptout?hl=de <br />
            <br />
            <span>Objection to data collection</span> <br />
            <br />
            You can prevent the collection of your data by Google Analytics by
            clicking on the following link. An opt-out cookie will be set, which
            will prevent the collection of your data during future visits to
            this website https://tools.google.com/dlpage/gaoptout?hl=de More
            information on how Google Analytics handles user data can be found
            in the Privacy Policy of Google:
            https://support.google.com/analytics/answer/6004245?hl=de
            <br />
            <br />
            <span> Demographic characteristics in Google Analytics</span>
            <br />
            <br />
            This website uses the "demographic characteristics" function of
            Google Analytics. This allows reports to be generated that
            statements on the age, gender and interests of visitors to the site.
            contain. This data comes from interest-based advertising from Google
            as well as from visitor data from third-party providers. This data
            cannot be assigned to a specific person. You can disable this
            function at any time via the ad settings in your Google account or
            block the collection of your data by Google Analytics as described
            in the section "Objection to data collection". shown generally
            prohibit. Facebook plugins (Like button) On our pages are plugins of
            the social network Facebook, Provider Facebook Inc, 1 Hacker Way,
            Menlo Park, California 94025, USA, are integrated. You can recognize
            the Facebook plugins by the Facebook logo or the "Like button"
            ("Like") on our site. page. An overview of the Facebook plugins can
            be found here: https://developers.facebook.com/docs/plugins/. When
            you visit our pages, the plugin establishes a direct connection
            between your browser and the between your browser and the Facebook
            server. Facebook receives the information that you have visited our
            site with your IP address. Page with your IP address. If you click
            on the Facebook "Like" button while you are logged in to your
            Facebook account, you can link the contents of our pages on your
            Facebook profile. This allows Facebook to associate the visit to our
            pages with your user account. to your user account. We would like to
            point out that we as the provider of the pages do not have any
            knowledge of the content of the transmitted data or its their use by
            Facebook. For more information on this you can find in the privacy
            policy of Facebook at https://de-de.facebook.com/policy.php. If you
            do not want Facebook to associate your visit to our pages with your
            Facebook user account account, please log out of your Facebook user
            account. Facebook user account.
            <br />
            <br /> <span>Twitter</span> <br />
            <br />
            Functions of the Twitter service are integrated on our pages. These
            functions are offered by Twitter Inc., 1355 Market Street, Suite
            900, San Francisco, CA 94103, USA. By using Twitter and the
            "Re-Tweet" function, the websites you visit will be linked to your
            websites you visit are linked to your Twitter account and made known
            to other made known to other users. In the process, data is also
            transferred to Twitter transmitted. We would like to point out that
            we as the provider of the pages do not have any knowledge of the
            content of the transmitted data or its their use by Twitter. Further
            information on this can be found you can find in the privacy policy
            of Twitter at https://twitter.com/privacy. Your privacy settings at
            Twitter can be found in the account settings at:
            https://twitter.com/account/settings
            <br />
            <br />
            <span> Google+</span> <br />
            <br />
            Our pages use functions of Google+. The provider is Google Inc, 1600
            Amphitheatre Parkway Mountain View, CA 94043, USA. Collection and
            dissemination of information: With the help of the Google+ button,
            you can publish information worldwide. publish. Through the Google+
            button, you and other users will receive other users receive
            personalized content from Google and our partners. Google stores
            both the information that you have given for +1 for a content, as
            well as information about the page you were Page you were viewing
            when you clicked +1. Your +1 can be as references together with your
            profile name and your photo in Google services, such as in search
            results or in your Google profile, or in other places on websites
            and ads on the Internet. Internet. Google records information about
            +1 activities in order to improve Google services for you and
            others. improve. In order to use the Google+ button, you need a
            globally visible, public Google profile, which must contain at least
            the name chosen for the profile. This name is used in all Google
            services. In some cases, this name may also replace another name
            that you use when used when sharing content through your Google
            Account. The identity of your Google profile may be displayed to
            users who know your know your email address or have other
            identifying information about you. information about you. Use of
            Collected Information: In addition to the uses explained above, we
            will the information you provide will be used in accordance with the
            applicable Google Privacy Policy. Google may publish may publish
            aggregate statistics about the +1 activities of users or passes
            these on to users and partners, such as publishers publishers,
            advertisers or affiliated websites.
            <br />
            <br /> <span>Instagram</span> <br />
            <br />
            On our pages are functions of the service Instagram are integrated.
            These functions are offered by Instagram Inc, 1601 Willow Road,
            Menlo Park, CA, 94025, USA. If you are logged in to your Instagram
            account, you can click on the the Instagram button, you can link the
            content of our pages to your Instagram profile. Instagram profile.
            This allows Instagram to associate the visit of our pages to your
            user account. We point out, that we, as the provider of the pages,
            have no knowledge of the content of the transmitted data and their
            use by Instagram. For more information, please see the privacy
            policy from Instagram: https://instagram.com/about/legal/privacy/{" "}
            <br />
            <br />
            <span> Linkedin</span>
            <br />
            <br /> Our website uses functions of the LinkedIn network. Provider
            is the LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA
            94043, USA. Each time you access one of our pages that functions of
            LinkedIn, a connection to servers of LinkedIn is established.
            LinkedIn is established. LinkedIn is informed that you have visited
            our Internet pages with your IP address. If you click the "Recommend
            button" from LinkedIn and are logged into your account at LinkedIn,
            it is possible for LinkedIn to associate your visit to our website
            with you and your user account. our website to you and your user
            account. We point out that we, as the provider of the pages, have no
            knowledge of the of the content of the transmitted data and its use
            by LinkedIn. LinkedIn have. You can find more information on this in
            the Privacy Policy of LinkedIn at:
            https://www.linkedin.com/legal/privacy-policy <br />
            <br />
            <span>Pinterest</span>
            <br />
            <br /> On our site, we use social plugins of the social network
            Pinterest social network, which is operated by Pinterest Inc. 808
            Brannan Street San Francisco, CA 94103-490, USA ("Pinterest"). If
            you visit a page that contains such a plugin, your browser
            establishes a direct connection to the browser establishes a direct
            connection to the Pinterest servers. The plugin thereby transmits
            log data to the server of Pinterest server in the USA. This log data
            may contain your IP address, the address of the websites visited
            that also contain Pinterest functions, type and settings of the
            browser, date and time of the request, how you use Pinterest, and
            cookies. Further information on the purpose, scope and further
            processing and use of the data by processing and use of the data by
            Pinterest as well as your rights in this regard and options for
            protecting your privacy. privacy can be found in Pinterest's privacy
            policy: https://about.pinterest.com/de/privacy-policy <br />
            <br />
            <span> XING</span>
            <br />
            <br /> Our website uses functions of the XING network. Provider is
            XING AG, Dammtorstraße 29-32, 20354 Hamburg, Germany. Each time one
            of our pages containing XING functions is called up, a connection to
            XING a connection to XING servers is established. A storage of
            personal data does not take place to our knowledge. knowledge. In
            particular, no IP addresses are stored or the usage behavior is
            evaluated. Further information on data protection and the XING Share
            button can be found in the XING privacy policy at:
            https://www.xing.com/app/share?op=data_protection <br />
            <br />
            <span> Tumblr</span>
            <br />
            <br /> Our pages use buttons of the Tumblr service. The provider is
            Tumblr, Inc., 35 East 21st St, 10th Floor, New York, NY 10010, USA.
            These buttons allow you to share a post or page on Tumblr or to
            follow the provider on Tumblr. Tumblr. When you visit one of our
            websites with a Tumblr button button, the browser establishes a
            direct connection with the servers of Tumblr. of Tumblr. We have no
            influence on the scope of the data that Tumblr collects and
            transmits with the help of this plugin. According to the IP address
            of the user and the URL of the respective website are transmitted.
            Further information on this can be found in the Tumblr can be found
            in Tumblr's privacy policy at:
            https://www.tumblr.com/policy/de/privacy . <br />
            <br />
            <span> YouTube</span> <br />
            <br />
            Our website uses plugins from the Google-operated site YouTube. The
            operator of the pages is YouTube, LLC, 901 Cherry Ave, San Bruno, CA
            94066, USA. If you visit one of our websites equipped with a YouTube
            plugin, a connection is established to the servers of YouTube. to
            the servers of YouTube is established. In the process, the YouTube
            server which of our pages you have visited. If you are logged into
            your YouTube account, you enable YouTube to assign your surfing
            behavior directly to your personal to your personal profile. You can
            prevent this by logging out of your YouTube account. For more
            information on the handling of user data can be found in the privacy
            policy of YouTube at: https://www.google.de/intl/de/policies/privacy{" "}
            <br />
            <br />
            <span>SSL encryption</span>
            <br />
            <br />
            For reasons of security and to protect the transmission of
            confidential content transmission of confidential content, such as
            inquiries, that you send to us as the site operator, uses SSL
            encryption. You can recognize an encrypted connection by the fact
            that the address line of your browser changes from "http://" to
            "https://" and the the lock symbol in your browser line If SSL
            encryption is activated, the data can encryption is activated, the
            data that you transmit to us cannot be cannot be read by third
            parties. <br />
            <br />
            <span>Data collection for online application </span>
            <br />
            <br />
            In the course of your online application, we will collect the
            following personal data from you. the following personal application
            data will be collected and processed processed: Name, first name,
            address, telephone number, e-mail, application documents (letter of
            application, curriculum vitae, references, certificates, etc.),
            certificates, etc.). <br />
            <br />
            <span>Purpose of data collection / disclosure</span>
            <br />
            <br />
            The collection and processing of your personal application data is
            used exclusively for the purpose of filling vacancies filling
            vacancies within our company. Your data will only be passed on to
            the internal departments and specialist responsible for the specific
            application procedure. departments of our company responsible for
            the specific application procedure. Your application data will not
            be used for any other or forwarding of your application data to
            third parties will not take place. Retention period for application
            data Your personal application data is application data is
            automatically deleted six months after completion of the months
            after completion of the application process or immediately if we are
            unable to offer you a position, if we are unable to offer you a
            position. This does not apply if legal provisions prevent deletion,
            or if further storage is necessary for the storage is necessary for
            the purpose of providing evidence or you have have expressly
            consented to longer storage. <br />
            <br />
            <span> Storage for future job postings</span>
            <br />
            <br />
            If we are unable to offer you a position that is currently available
            but based on your profile we think that your application may be of
            could be of interest for future job offers, we will retain your
            future job offers, we will store your personal application data for
            twelve months for twelve months, provided you expressly consent to
            such storage and use. expressly consent. <br />
            <br />
            <span> Your rights</span> <br />
            <br />
            You have the following rights with respect to the personal data
            concerning you personal data concerning you: <br />
            -Right to information <br />
            -Right to rectification or cancellation <br />
            -Right to restriction of processing
            <br />
            -Right to object to processing <br />
            -Right to data portability
            <br /> You also have the right to complain to a data protection
            supervisory authority about the processing of your about the
            processing of your personal data by us. <br />
            <br />
            <span> Objection advertising mails</span>
            <br />
            <br /> The use of contact data published within the scope of the
            imprint published contact data for the purpose of sending
            unsolicited unsolicited advertising and information material is
            hereby prohibited. contradicted. The operators of the pages
            expressly reserve the right legal steps in the case of unsolicited
            sending of advertising information of unsolicited advertising
            information, such as spam e-mails. <br />
            <br />
            <span> Person responsible and data protection officer</span>
            <br />
            <br /> The person responsible is m.Doc GmbH, Ursulaplatz 1, 50668
            Cologne, e-mail: info@mdoc.one . For questions regarding data
            protection please contact our data protection officer at
            datenschutz@mdoc.one.
          </p>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default DataProtection;
