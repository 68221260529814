let initialState = "de";

export default (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_LANGUAGE":
      return action.payload.language;
    default:
      return state;
  }
};
