import React from "react";
import Footer from "../../components/Footer";
import "./index.scss";
import { useSelector } from "react-redux";

const Impressum = () => {
  let language = useSelector((state) => state.language);
  return (
    <div className="containerImpressum">
      <div className="containerImpressum__box">
        <h1>Impressum</h1>
        {language === "de" ? (
          <p className="containerImpressum__box__paragraph">
            Information nach § 5 TMG <br />
            m.Doc GmbH <br />
            Ursulaplatz 1 <br />
            50668 Köln Deutschland <br />
            <br />
            <span> Geschäftsführung: </span>Admir Kulin <br />
            <br />
            <span> Kontakt</span> <br />
            <br />
            Telefon: +49 (0)221 669 637 00 <br />
            E-Mail: info@mdoc.one Web: www.mdoc.one <br />
            <br />
            <span> Eintragung ins Handelsregister</span> <br />
            <br />
            <span> Registergericht:</span> Amtsgericht Köln <br />
            <span> Registernummer:</span> HRB 88659 <br />
            <br />
            <span> USt-ID</span> <br />
            <br />
            <span>Umsatzsteuer-Identifikationsnummer gemäß § 27a UStG:</span>
            DE308861740
            <br />
            <br />
            <span> Inhaltlich Verantwortlicher nach § 55 Abs. 2 RStV</span>
            <br />
            Admir Kulin <br />
            <br />
            <span>Haftungsausschluss </span> <br />
            <br />
            <span> Haftung für Inhalte</span>
            <br />
            <br /> Die Inhalte unserer Seiten wurden mit größter Sorgfalt
            erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der
            Inhalte können wir jedoch keine Gewähr übernehmen. Als
            Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
            diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§
            8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
            übermittelte oder gespeicherte fremde Informationen zu überwachen
            oder nach Umständen zu forschen, die auf eine rechtswidrige
            Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung
            der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
            hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab
            dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
            Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
            diese Inhalte umgehend entfernen. <br />
            <br />
            <span> Haftung für Links </span>
            <br />
            <br />
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten
            Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
            nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen. <br />
            <br />
            <span> Urheberrecht</span>
            <br /> Die durch die Seitenbetreiber erstellten Inhalte und Werke
            auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
            Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
            Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
            gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
            werden wir derartige Inhalte umgehend entfernen.
          </p>
        ) : (
          <p>
            Information according to § 5 TMG <br />
            m.Doc GmbH <br />
            Ursulaplatz 1 <br />
            50668 Köln Deutschland <br />
            <br />
            <span> Management: </span>Admir Kulin <br />
            <br />
            <span> Contact</span> <br />
            <br />
            Phone: +49 (0)221 669 637 00 <br />
            E-Mail: info@mdoc.one Web: www.mdoc.one <br />
            <br />
            <span> Entry in the Commercial Register</span> <br />
            <br />
            <span> Registry Court:</span> Local Court Cologne <br />
            <span> Registration number:</span> HRB 88659 <br />
            <br />
            <span> USt-ID</span> <br />
            <br />
            <span>
              Sales tax identification number according to § 27a UStG:
            </span>
            DE308861740
            <br />
            <br />
            <span>Responsible for content according to § 55 Abs. 2 RStV</span>
            <br />
            Admir Kulin <br />
            <br />
            <span>Disclaimer </span> <br />
            <br />
            <span> Liability for contents</span>
            <br />
            <br /> The contents of our pages were created with the utmost care
            created. For the accuracy, completeness and timeliness of the
            contents contents, however, we cannot assume any liability. As
            service provider, we are responsible for our own content on these
            pages under on these pages according to the general laws. According
            to §§ 8 to 10 TMG we are not obligated as a service provider, to
            monitor transmitted or stored third-party information or to
            investigate or to investigate circumstances that indicate illegal
            activity. illegal activity. Obligations to remove or block the use
            of information under general laws remain unaffected. remain
            unaffected by this. However, liability in this regard is only
            possible from the time possible only from the time of knowledge of a
            specific infringement. If we become aware of any such infringements,
            we will remove the relevant content remove these contents
            immediately.
            <br />
            <br />
            <span> Liability for links </span>
            <br />
            <br />
            Our offer contains links to external websites of third parties, on
            whose contents we have no influence. on whose contents we have no
            influence. Therefore, we can for these foreign contents also no
            guarantee. For the contents of the linked pages is always the
            respective provider or operator of the provider or operator of the
            pages. The linked pages were checked checked for possible legal
            violations at the time of linking. Illegal contents were not
            recognizable at the time of linking. recognizable at the time of
            linking. A permanent control of the contents of the linked pages is
            linked pages is not reasonable without concrete evidence of a
            violation of law. not reasonable. If we become aware of any
            infringements of the law, we will immediately remove remove such
            links immediately.
            <br />
            <br />
            <span> Copyright</span>
            <br />
            <br /> The contents and works created by the operators of this
            website on these pages are subject to German copyright law. The
            duplication, processing, distribution, or any form of
            commercialization use outside the limits of copyright law requires
            the written consent of the written consent of the respective author
            or creator. Downloads and copies of this site are only permitted for
            private, non-commercial use. commercial use. As far as the contents
            on this not created by the operator, the copyrights of third parties
            are respected. copyrights of third parties. In particular,
            third-party content is identified as such. marked as such. Should
            you nevertheless become aware of a copyright infringement, we ask
            for an appropriate notice. appropriate notice. If we become aware of
            any infringements of the law we will remove such content
            immediately.
          </p>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default Impressum;
