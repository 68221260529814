import React, { useEffect, useState, useRef } from "react";
import "./index.scss";
import { useLocation, useNavigate } from "react-router";
import animationModule from "../../modules/animationModule";
import { useDispatch, useSelector } from "react-redux";
import * as changeLanguage from "../../actions/languageAction";

const Header = () => {
  let homeRef = useRef();
  let productRef = useRef();
  let pricingRef = useRef();
  let supportRef = useRef();
  let faqRef = useRef();

  let homeMobileRef = useRef();
  let productMobileRef = useRef();
  let pricingMobileRef = useRef();
  let supportMobileRef = useRef();
  let faqMobileRef = useRef();

  let [mobileMenu, setMobileMenu] = useState(false);
  let curLocation = useLocation();
  let curNavigate = useNavigate();
  let language = useSelector((state) => state.language);
  let languageDispatch = useDispatch();

  useEffect(() => {
    homeRef.current.style.textDecoration = "none";
    productRef.current.style.textDecoration = "none";
    // pricingRef.current.style.textDecoration = "none";
    supportRef.current.style.textDecoration = "none";
    faqRef.current.style.textDecoration = "none";

    homeMobileRef.current.style.textDecoration = "none";
    productMobileRef.current.style.textDecoration = "none";
    // pricingMobileRef.current.style.textDecoration = "none";
    supportMobileRef.current.style.textDecoration = "none";
    faqMobileRef.current.style.textDecoration = "none";
    if (curLocation.pathname === "/") {
      homeRef.current.style.textDecoration = "underline solid 2px";
      homeMobileRef.current.style.textDecoration = "underline solid 2px";
    }
    if (curLocation.pathname === "/product") {
      productRef.current.style.textDecoration = "underline solid 2px";
      productMobileRef.current.style.textDecoration = "underline solid 2px";
    }
    // if (curLocation.pathname === "/pricing") {
    //   pricingRef.current.style.textDecoration = "underline solid 2px";
    //   pricingMobileRef.current.style.textDecoration = "underline solid 2px";
    // }
    if (curLocation.pathname === "/support") {
      supportRef.current.style.textDecoration = "underline solid 2px";
      supportMobileRef.current.style.textDecoration = "underline solid 2px";
    }
    if (curLocation.pathname === "/faq") {
      faqRef.current.style.textDecoration = "underline solid 2px";
      faqMobileRef.current.style.textDecoration = "underline solid 2px";
    }
  }, [curLocation]);

  return (
    <div className="containerHeader">
      <div className="containerHeader__inner">
        <div className="containerHeader__logoBox">
          <a
            onClick={() => {
              animationModule(
                curNavigate,
                "/",
                document.querySelector(".routesWrapper")
              );
            }}
          >
            <img
              src="/Assets/logoSmart.png"
              alt="logo"
              className="containerHeader__logoBox__logo"
            />
          </a>
        </div>
        <div className="containerHeader__itemBox">
          <a
            onClick={() => {
              animationModule(
                curNavigate,
                "/",
                document.querySelector(".routesWrapper")
              );
            }}
            ref={homeRef}
            className="containerHeader__itemBox__item"
          >
            {language === "de" ? "Start" : "Home"}
          </a>
          <a
            onClick={() => {
              animationModule(
                curNavigate,
                "/product",
                document.querySelector(".routesWrapper")
              );
            }}
            ref={productRef}
            className="containerHeader__itemBox__item"
          >
            {language === "de" ? "Produkt" : "Product"}
          </a>
          {/* <a
            onClick={() => {
              animationModule(
                curNavigate,
                "/pricing",
                document.querySelector(".routesWrapper")
              );
            }}
            ref={pricingRef}
            className="containerHeader__itemBox__item"
          >
            {language === "de" ? "Preise" : "Pricing"}
          </a> */}
          <a
            onClick={() => {
              animationModule(
                curNavigate,
                "/support",
                document.querySelector(".routesWrapper")
              );
            }}
            ref={supportRef}
            className="containerHeader__itemBox__item"
          >
            {language === "de" ? "Support" : "Support"}
          </a>
          <a
            onClick={() => {
              animationModule(
                curNavigate,
                "/faq",
                document.querySelector(".routesWrapper")
              );
            }}
            ref={faqRef}
            className="containerHeader__itemBox__item"
          >
            FAQ
          </a>
          <div className="containerHeader__itemBox__box">
            <p
              style={{ marginRight: "10px" }}
              className="containerHeader__itemBox__item"
            >
              {language === "de" ? "Registrieren" : "Register"}
            </p>
            <img
              className="containerHeader__itemBox__item__arrow"
              src="/Assets/arrowDown.png"
              alt="arrow"
            />
            <div className="containerHeader__itemBox__box__dropdown">
              <div className="containerHeader__itemBox__box__dropdown__inner">
                <img
                  className="containerHeader__itemBox__box__dropdown__inner__logo"
                  src="/Assets/patient.png"
                  alt=""
                />
                <a
                  style={{ textDecoration: "none", color: "#222222" }}
                  href="https://smartpractice.mdoc.one/login"
                >
                  Patient
                </a>
              </div>
              <div className="containerHeader__itemBox__box__dropdown__divider"></div>
              <div className="containerHeader__itemBox__box__dropdown__inner">
                <img
                  className="containerHeader__itemBox__box__dropdown__inner__logo"
                  src="/Assets/doctor.png"
                  alt=""
                />
                {language === "de" ? (
                  <p
                    onClick={() => {
                      animationModule(
                        curNavigate,
                        "/register",
                        document.querySelector(".routesWrapper")
                      );
                    }}
                  >
                    Mediziner
                  </p>
                ) : (
                  <p
                    onClick={() => {
                      animationModule(
                        curNavigate,
                        "/register",
                        document.querySelector(".routesWrapper")
                      );
                    }}
                  >
                    Medical Practitioner
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="containerHeader__itemBox__box">
            <img
              className="containerHeader__itemBox__item__user"
              src="/Assets/user.png"
              alt="eser"
            />
            <a
              className="containerHeader__itemBox__item__user__deco"
              href="https://smartpractice.mdoc.one/login"
            >
              <p className="containerHeader__itemBox__item">Login</p>
            </a>
          </div>
          <div className="containerHeader__itemBox__box">
            {language === "de" ? (
              <div className="containerHeader__itemBox__box__language">
                <img
                  className="containerHeader__itemBox__item__uk"
                  src="/Assets/german.png"
                  alt="UK"
                />
                <p
                  style={{
                    marginLeft: "5px",
                    marginRight: "10px",
                    width: "25px",
                  }}
                  className="containerHeader__itemBox__item"
                >
                  De
                </p>
              </div>
            ) : (
              <div className="containerHeader__itemBox__box__language">
                <img
                  className="containerHeader__itemBox__item__uk"
                  src="/Assets/united.png"
                  alt="UK"
                />
                <p
                  style={{
                    marginLeft: "5px",
                    marginRight: "10px",
                    width: "25px",
                  }}
                  className="containerHeader__itemBox__item"
                >
                  En
                </p>
              </div>
            )}
            <img
              className="containerHeader__itemBox__item__arrow"
              src="/Assets/arrowDown.png"
              alt="arrow"
            />
            <div className="containerHeader__itemBox__box__lang">
              <p
                onClick={() => {
                  languageDispatch(changeLanguage.changeLanguage("en"));
                }}
              >
                English
              </p>
              <div className="containerHeader__itemBox__box__dropdown__divider"></div>
              <p
                onClick={() => {
                  languageDispatch(changeLanguage.changeLanguage("de"));
                }}
              >
                Deutsch
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="containerHeader__mobile">
        <img
          className="containerHeader__mobile__icon"
          onClick={() => {
            setMobileMenu(!mobileMenu);
          }}
          src={mobileMenu ? "/Assets/close.png" : "/Assets/menu.png"}
          alt=""
        />
        <div
          className={
            mobileMenu
              ? "containerHeader__mobile__box containerHeader__mobile__box--active"
              : "containerHeader__mobile__box"
          }
        >
          <a
            ref={homeMobileRef}
            onClick={() => {
              animationModule(
                curNavigate,
                "/",
                document.querySelector(".routesWrapper")
              );
            }}
          >
            <img
              src="/Assets/logoSmart.png"
              alt="logo"
              className="containerHeader__logoBox__logo"
            />
          </a>
          <a
            onClick={() => {
              animationModule(
                curNavigate,
                "/",
                document.querySelector(".routesWrapper")
              );
            }}
            ref={homeMobileRef}
            className="containerHeader__itemBox__item"
          >
            {language === "de" ? "Start" : "Home"}
          </a>
          <a
            onClick={() => {
              animationModule(
                curNavigate,
                "/product",
                document.querySelector(".routesWrapper")
              );
            }}
            ref={productMobileRef}
            className="containerHeader__itemBox__item"
          >
            {language === "de" ? "Produkt" : "Product"}
          </a>
          {/* <a
            onClick={() => {
              animationModule(
                curNavigate,
                "/pricing",
                document.querySelector(".routesWrapper")
              );
            }}
            ref={pricingMobileRef}
            className="containerHeader__itemBox__item"
          >
            {language === "de" ? "Preise" : "Pricing"}
          </a> */}
          <a
            onClick={() => {
              animationModule(
                curNavigate,
                "/support",
                document.querySelector(".routesWrapper")
              );
            }}
            ref={supportMobileRef}
            className="containerHeader__itemBox__item"
          >
            {language === "de" ? "Support" : "Support"}
          </a>
          <a
            onClick={() => {
              animationModule(
                curNavigate,
                "/faq",
                document.querySelector(".routesWrapper")
              );
            }}
            ref={faqMobileRef}
            className="containerHeader__itemBox__item"
          >
            FAQ
          </a>
          <div className="containerHeader__itemBox__box">
            <p
              style={{ marginRight: "10px" }}
              className="containerHeader__itemBox__item"
            >
              {language === "de" ? "Registrieren" : "Register"}
            </p>
            <img
              className="containerHeader__itemBox__item__arrow"
              src="/Assets/arrowDown.png"
              alt="arrow"
            />
            <div className="containerHeader__itemBox__box__dropdown">
              <div className="containerHeader__itemBox__box__dropdown__inner">
                <img
                  className="containerHeader__itemBox__box__dropdown__inner__logo"
                  src="/Assets/patient.png"
                  alt=""
                />
                <a
                  style={{ textDecoration: "none", color: "#222222" }}
                  href="https://smartpractice.mdoc.one/login"
                >
                  Patient
                </a>
              </div>
              <div className="containerHeader__itemBox__box__dropdown__divider"></div>
              <div className="containerHeader__itemBox__box__dropdown__inner">
                <img
                  className="containerHeader__itemBox__box__dropdown__inner__logo"
                  src="/Assets/doctor.png"
                  alt=""
                />
                {language === "de" ? (
                  <p
                    onClick={() => {
                      animationModule(
                        curNavigate,
                        "/register",
                        document.querySelector(".routesWrapper")
                      );
                    }}
                  >
                    Mediziner
                  </p>
                ) : (
                  <p
                    onClick={() => {
                      animationModule(
                        curNavigate,
                        "/register",
                        document.querySelector(".routesWrapper")
                      );
                    }}
                  >
                    Medical Practitioner
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="containerHeader__itemBox__box">
            <img
              className="containerHeader__itemBox__item__user"
              src="/Assets/user.png"
              alt="eser"
            />
            <a
              className="containerHeader__itemBox__item__user__deco"
              href="https://smartpractice.mdoc.one/login"
            >
              <p className="containerHeader__itemBox__item">Login</p>
            </a>
          </div>
          <div className="containerHeader__itemBox__box__langMobile">
            <p
              onClick={() => {
                languageDispatch(changeLanguage.changeLanguage("en"));
              }}
            >
              English
            </p>
            <div className="containerHeader__itemBox__box__dropdown__divider"></div>
            <p
              onClick={() => {
                languageDispatch(changeLanguage.changeLanguage("de"));
              }}
            >
              Deutsch
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
