export default (navigateFunc, to, componentToAnimate) => {
  return new Promise((resolve) => {
    if (!componentToAnimate) {
      navigateFunc(to);
      return resolve();
    }
    componentToAnimate.animate(
      [
        { opacity: getComputedStyle(componentToAnimate).opacity },
        { opacity: 0 },
      ],
      {
        duration: 300,
        iterations: 1,
        fill: "both",
        easing: "ease-in-out",
      }
    ).onfinish = () => {
      navigateFunc(to);
      setTimeout(() => {
        componentToAnimate.animate(
          [
            { opacity: getComputedStyle(componentToAnimate).opacity },
            { opacity: 1 },
          ],
          {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease-in-out",
          }
        ).onfinish = () => {
          resolve();
        };
      }, 50);
    };
  });
};
