import React from "react";
import "./index.scss";

const Agb = () => {
  return (
    <div className="containerAgb">
      <div className="containerAgb__box">
        <p>
          <span className="containerAgb__box__title">
            Nutzungsbedingungen für die Smart Practice
          </span>
          <br />
          <br />
          Die nachfolgenden Nutzungsbedingungen regeln die Nutzung der unter der
          Bezeichnung „Smart Practice“ angebotenen Web- und Appanwendung
          (nachfolgend: „Anwendung“) der m.Doc GmbH, Köln. Mit Smart Practice
          bringen wir Patienten und Gesundheitsdienstleister zusammen und
          erleichtern eine digitale Gesundheitsversorgung, insbesondere bieten
          wir Videosprechstunden an. Wir selbst erbringen aber keine ärztlichen
          bzw. therapeutischen Leistungen. Der Behandlungsvertrag kommt
          ausschließlich zwischen Patient und Gesundheitsdienstleister zustande
          und ist nicht Gegenstand unserer Leistung. m.Doc übernimmt keine
          Haftung dafür, dass der Gesundheitsdienstleister seine Leistung
          ordnungsgemäß erbringt. <br />
          <br />
          <span>1 Geltungsbereich</span>
          <br /> <br />
          Die Nutzung der Anwendung ist unseren Kunden sowie deren
          Patientenerlaubt. Die Anwendung ist zu erreichen unter dem Link
          https://smartpractice.mdoc.one/. <br />
          <br />
          <span>2 Voraussetzungen der Nutzung als Patient</span> <br />
          <br />
          1. Die Anwendung darf nur von Patienten genutzt werden, die 18 Jahre
          oder älter sind.
          <br /> <br />
          2. Für die Nutzung der Anwendung als Patient ist ein Desktop-PC oder
          ein mobiles Endgerät erforderlich, welches einen Internetzugang hat
          und eine aktuelle Browserversion:
          <br /> Desktop-Computer <br />
          · Windows 8.1, oder neuer <br />· macOS 10.13, oder neuer
          <br /> · Linux Ubuntu 14.04, oder neuer
          <br />
          Mobile Geräte <br />· Android 8, oder neuer
          <br /> · iOS 12, oder neuer Unterstützte Internetbrowser <br />
          · Chrome 29, oder neuer <br />
          · Mozilla Firefox 22, oder neuer <br />
          · Safari für iOS 7.0, oder neuer <br />
          · (spez. SetUp beachten: Safari-mdoc-Einstellungen.pdf) <br />
          · Chrome für Android 79, oder neuer <br />
          Wir sind nicht für die Einhaltung dieser Anforderungen verantwortlich.
          Dies obliegt ausschließlich Ihnen. <br />
          <br />
          3. Wir stellen die Appanwendung kostenlos zum Download, zur
          Installation und zur Nutzung für Apple iPhone® über iTunes® App Store
          sowie für Android-Geräte über den Google PlayStore zur Verfügung. Die
          Rechtsbeziehung mit dem Portalbetreiber (App-Store) ist nicht
          Gegenstand dieser Nutzungsbedingungen. Diesbezüglich gelten nur die
          Bedingungen des Portalbetreibers. Die Kosten für die mobile
          Internetnutzung richten sich nach den Tarifen Ihres Anbieters.
          <br />
          4. Sie können die Anwendung sowohl mit Registrierung (d.h. mit
          Benutzerkonto) als auch ohne nutzen. In beiden Fällen kann Ihr Arzt
          Ihren Namen für die Videosprechstunde sehen. Bei der Registrierung
          erstellen Sie selbst einen Benutzernamen und ein Passwort für die
          Anmeldung. Bei der Nutzung ohne Registrierung teilt Ihnen Ihr Arzt ein
          Einmalpasswort mit, das 15 Minuten vor bis 15 Minuten nach dem Termin
          der Videosprechstunde gültig ist. Sie geben dieses Passwort ein, um
          die Videosprechstunde ohne Registrierung zu nutzen.
          <br />
          5. Sie sind dafür verantwortlich, dass Ihr Passwort und Ihre
          Benutzerkennung keinem Dritten zugänglich sind. Bitte achten Sie bei
          Eingabe der Daten zur Registrierung darauf, dass diese nicht durch
          Dritte ausgespäht werden können. Haben Sie den Verdacht, dass jemandem
          diese unberechtigt bekannt sind oder diese unberechtigt verwendet
          werden, dann wenden Sie sich an Ihren Arzt. Wir empfehlen Ihnen, Ihre
          Geräte mit einer Code-Sperre zu versehen, damit sie z.B. im Falle
          eines Diebstahls besser geschützt sind. Wir empfehlen aufgrund der
          Sensibilität Ihrer Daten, dass Sie sich nach Erledigung Ihres
          Anliegens stets manuell ausloggen. Wir übernehmen bei Missbrauch
          keinerlei Haftung.
          <br />
          6. Aufzeichnungen der Videosprechstunde sind nicht gestattet.
          <br />
          <br />
          <span>3 Voraussetzungen der Nutzung als Arzt</span> <br />
          <br />
          1. Für die Nutzung der Webanwendung als Arzt ist ein Desktop-PC
          erforderlich, welches einen Internetzugang hat und die aktuellste
          Version des Google Chrome Browsers unterstützt. <br />
          Als Arzt haben Sie mindestens folgend apparative Ausstattung: <br />·
          Kamera <br />
          · Bildschirm (Monitor, Display etc.): • Bildschirmdiagonale:
          mindestens 3 Zoll <br />
          · Auflösung: mindestens: 640x480 px <br />
          · Bandbreite: Mindestens 2000 kbit/s im Download <br />
          · Mikrofon <br />
          · Tonwiedergabeeinheit
          <br />
          Wir sind nicht für die Einhaltung dieser Anforderungen verantwortlich.
          Dies obliegt ausschließlich Ihnen. Voraussetzung für die Nutzung ist
          eine vorherige Registrierung über unsere Webseite. Nach erfolgreicher
          Registrierung erhalten Sie Benutzernamen und Passwörter für die
          Behandelnden. Die Passwörter müssen Sie bei der erstmaligen Anmeldung
          ändern. Sie können als Arzt immer nur einen Videoanruf gleichzeitig
          planen und durchführen, sollten sich Termine überschneiden, werden
          Patienten in ein virtuelles Wartezimmer geleitetAlle weiteren Anrufe
          werden in Ihr virtuelles Wartezimmer geleitet. <br />
          2. Sie sind dafür verantwortlich, dass Ihr Passwort und Ihre
          Benutzerkennung keinem Dritten zugänglich sind. Bitte achten Sie bei
          Eingabe der Daten zur Registrierung darauf, dass diese nicht durch
          Dritte ausgespäht werden können. Haben Sie den Verdacht, dass jemandem
          diese unberechtigt bekannt sind oder diese unberechtigt verwendet
          werden, dann wenden Sie sich an uns. Wir empfehlen Ihnen, Ihre Geräte
          mit einer Sperre zu versehen, damit sie z.B. im Falle eines Diebstahls
          besser geschützt sind. Wir empfehlen aufgrund der Sensibilität Ihrer
          Daten, dass Sie sich nach Erledigung Ihres Anliegens stets manuell
          ausloggen. Wir übernehmen bei Missbrauch keinerlei Haftung. <br />
          3. Aufzeichnungen der Videosprechstunde sind nicht gestattet. <br />
          <br />
          <span>4 Leistungsumfang </span>
          <br />
          <br />
          1. Die Anwendung bietet Ihnen folgende inhaltliche Funktionen: <br />·
          Anmeldung als Patient oder als Arzt <br />· Selbstregistrierung als
          Patient
          <br /> · Nutzung der Videosprechstunde ohne Registrierung als Patient
          <br />· Buchung/Planung eines Termins für eine Videosprechstunde
          <br />· Durchführung der Videosprechstunde zwischen Arzt und Patient,
          sowie Gruppenkonsultation mit mehreren Ärzten und Patienten <br />·
          Chatfunktion zwischen Ärzten und zwischen Arzt und Patienten <br />
          2. Die Anwendung bietet Ihnen folgende technische Funktionen: Die
          Übertragung der Videosprechstunde findet über eine Peer-to-Peer
          -Verbindung zwischen Ihnen und Ihrem Patienten, ohne Nutzung eines
          zentralen Servers, statt. Sämtliche Inhalte der Videosprechstunde sind
          während des gesamten Übertragungsprozesses nach dem Stand der Technik
          Ende-zu-Ende verschlüsselt. Die eingesetzte Software ist bei
          Schwankungen der Verbindungsqualität bezüglich der Ton- und
          Bildqualität adaptiv. Die Entscheidung über die Durch- bzw.
          Fortführung der Videosprechstunde bei abnehmender Ton- und
          Bildqualität obliegt Ihnen als Patient und Arzt. Sämtliche Inhalte der
          Videosprechstunde werden durch uns weder eingesehen noch gespeichert.
          Wir nutzen nur Server im Europäischen Wirtschaftsraum (EWR). Alle
          Metadaten werden nach spätestens drei Monaten gelöscht und werden nur
          für die zur Abwicklung der Videosprechstunde notwendigen Abläufe
          genutzt. Wir geben die Daten nicht weiter. Wir schalten keine Werbung
          im Rahmen der Anwendung. <br />
          <br />
          <span>5 Nutzungseinräumung</span>
          <br />
          <br /> 1. Die über die Anwendung angebotenen Inhalte sind
          urheberrechtlich geschützt. Wir stellen Ihnen diese ausschließlich für
          eigene nicht kommerzielle Zwecke zur Verfügung. Wir räumen Ihnen für
          die Anwendung ein einfaches, nicht ausschließliches Nutzungsrecht ein.
          <br />
          2. Es ist Ihnen nicht gestattet, die Anwendung ganz oder in Teilen zu
          vertreiben oder anderweitig Dritten zu übertragen (einschließlich der
          Vermietung, Verpachtung, Leihgabe oder Unterlizenzierung). Es ist
          ferner untersagt, den Programmcode der Anwendung oder Teile hiervon zu
          verändern, Rückwärts zu entwickeln (Revers Engineering), zu
          dekompilieren, zu disassemblieren oder den Quellcode auf andere Weise
          festzustellen sowie abgeleitete Werke der Anwendung zu erstellen, zu
          vervielfältigen oder zu dekompilieren. <br />
          <br />
          <span>6 Verfügbarkeit</span> <br />
          <br />
          1. Die Leistungen der Anwendung werden Ihnen unter dem Vorbehalt der
          Verfügbarkeit angeboten. Wir bemühen uns abzusichern, dass Ihnen die
          Leistungen ohne Störungen zur Verfügung stehen. Durch Wartungsarbeiten
          und/oder Weiterentwicklung und/oder andere Störungen können die
          Nutzungsmöglichkeiten eingeschränkt und/oder zeitweise unterbrochen
          werden. Daraus entstehen Ihnen keine Entschädigungsansprüche.
          <br /> 2. Wir sind berechtigt, die angebotenen Dienste und Leistungen
          jederzeit nach eigenem Ermessen ohne Ankündigung zu ändern oder
          einzustellen.
          <br />
          <br /> <span>7 Datenschutz </span>
          <br />
          <br />
          Bitte beachten Sie die Datenschutzerklärung zu dieser Anwendung.
          <br />
          <br />
          <span>8 Haftung</span>
          <br />
          <br /> 1. Generelle Grundsätze Wir übernehmen keine Garantien/Gewähr
          hinsichtlich inhaltlicher Richtigkeit, Aktualität und Vollständigkeit
          der Inhalte oder Eignung für bestimmte Verwendungszwecke/
          Verfügbarkeit, Zuverlässigkeit, Funktionalität oder Eignung der
          Anwendung für Ihre Zwecke. Eine Haftung ist, soweit nicht nach dem
          Produkthaftungsgesetz, wegen Vorsatzes, grober Fahrlässigkeit, wegen
          Verletzung des Lebens, des Körpers oder Gesundheit, wegen der
          Übernahme einer Beschaffenheitsgarantie, wegen arglistigen
          Verschweigens eines Mangels oder wegen der Verletzung wesentlicher
          Vertragspflichten zwingend gehaftet wird, ausgeschlossen. Wesentliche
          Vertragspflichten sind solche Pflichten, deren Erfüllung die
          ordnungsgemäße Durchführung eines Vertrages überhaupt erst ermöglicht
          und auf deren Einhaltung die Vertragsparteien regelmäßig vertrauen
          dürfen. Der Schadensersatz wegen Verletzung wesentlicher
          Vertragspflichten ist auf den vertragstypischen, vorhersehbaren
          Schaden begrenzt, soweit nicht Vorsatz oder grobe Fahrlässigkeit
          vorliegen. <br />
          <br />
          2.Haftung für Inhalte Als Diensteanbieter sind wir gemäß § 7 Abs. 1
          Telemediengesetz (TMG) für eigene Inhalte in dieser Anwendung nach den
          allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
          Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
          gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
          forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen. <bn />
          <br />
          <br />
          <span>9 Änderungen</span>
          <br />
          <br /> Wir behalten uns das Recht vor, Funktionen hinzuzufügen oder zu
          entfernen. Wir behalten uns das Recht vor, die Nutzungsbedingungen zu
          ändern und anzupassen. Hierüber werden Sie in geeigneter Weise
          informiert. <br />
          <br />
          <span> 10 Anwendbares Recht</span> <br />
          <br /> Das Rechtsverhältnis zwischen Ihnen und uns unterliegt dem
          Recht der Bundesrepublik Deutschland unter Ausschluss des
          UN-Kaufrechts. Die gesetzlichen Vorschriften zur Beschränkung der
          Rechtswahl und zur Anwendbarkeit zwingender Vorschriften insbesondere
          des Staates, in dem Sie als Verbraucher Ihren gewöhnlichen Aufenthalt
          haben, bleiben unberührt. Sollten einzelne Bestimmungen dieser
          Nutzungsbedingungen unwirksam oder undurchführbar sein oder werden,
          bleibt die Wirksamkeit der anderen Bedingungen im Übrigen unberührt.
          <br />
          <br /> Stand: Februar 2022
        </p>
      </div>
    </div>
  );
};

export default Agb;
