import React from "react";
import "./index.scss";
import { useNavigate } from "react-router";
import animationModule from "../../modules/animationModule";
import { useSelector } from "react-redux";

const Footer = () => {
  let curNavigate = useNavigate();
  let language = useSelector((state) => state.language);

  return (
    <div className="containerFooter">
      <div className="containerFooter__inner">
        <p className="containerFooter__inner__gmbh">© 2022 m.Doc GmbH</p>
        <div className="containerFooter__inner__box">
          {language === "de" ? (
            <a
              onClick={() => {
                animationModule(
                  curNavigate,
                  "/impressum",
                  document.querySelector(".routesWrapper")
                );
              }}
              className="containerFooter__inner__anker"
            >
              Impressum
            </a>
          ) : (
            <a
              onClick={() => {
                animationModule(
                  curNavigate,
                  "/impressum",
                  document.querySelector(".routesWrapper")
                );
              }}
              className="containerFooter__inner__anker"
            >
              Impressum
            </a>
          )}
          <div className="containerFooter__inner__divider">|</div>
          {language === "de" ? (
            <a
              onClick={() => {
                animationModule(
                  curNavigate,
                  "/data",
                  document.querySelector(".routesWrapper")
                );
              }}
              className="containerFooter__inner__anker"
            >
              Datenschutz
            </a>
          ) : (
            <a
              onClick={() => {
                animationModule(
                  curNavigate,
                  "/data",
                  document.querySelector(".routesWrapper")
                );
              }}
              className="containerFooter__inner__anker"
            >
              Data privacy
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Footer;
