import React, { useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";

const Dropdown = ({
  title,
  sub1,
  sub2,
  sub3,
  sub4,
  sub5,
  sub6,
  sub7,
  sub8,
  sub9,
  sub10,
  sub11,
  sub12,
  sub13,
  sub14,
  titleEn,
  sub1En,
  sub2En,
  sub3En,
  sub4En,
  sub5En,
  sub6En,
  sub7En,
  sub8En,
  sub9En,
  sub10En,
  sub11En,
  sub12En,
  sub13En,
  sub14En,
}) => {
  let [openBox, setOpenBox] = useState(false);
  let language = useSelector((state) => state.language);
  return (
    <div className="dropdownContainer__box__completeBox">
      <div
        className="dropdownContainer__box__inner"
        onClick={() => {
          setOpenBox(!openBox);
        }}
      >
        {language === "de" ? (
          <p
            className={
              openBox
                ? "dropdownContainer__box__inner__bold"
                : "dropdownContainer__box__inner__normal"
            }
          >
            {title}
          </p>
        ) : (
          <p
            className={
              openBox
                ? "dropdownContainer__box__inner__bold"
                : "dropdownContainer__box__inner__normal"
            }
          >
            {titleEn}
          </p>
        )}
        {openBox ? (
          <img src="/Assets/remove.png" alt="" />
        ) : (
          <img src="/Assets/add.png" alt="" />
        )}
      </div>
      <div
        className={
          openBox
            ? "dropdownContainer__box__inner__toShow dropdownContainer__box__inner__toHide "
            : "dropdownContainer__box__inner__toHide "
        }
      >
        {sub1 ? (
          <p>
            {language === "de" ? (
              <div>
                {sub1}
                <br />
                <br />
              </div>
            ) : (
              <div>
                {sub1En}
                <br />
                <br />
              </div>
            )}
          </p>
        ) : (
          ""
        )}
        {sub2 ? (
          <p>
            {language === "de" ? (
              <div>
                {sub2}
                <br />
                <br />
              </div>
            ) : (
              <div>
                {sub2En}
                <br />
                <br />
              </div>
            )}
          </p>
        ) : (
          ""
        )}
        {sub3 ? (
          <p>
            {language === "de" ? (
              <div>
                {sub3}
                <br />
                <br />
              </div>
            ) : (
              <div>
                {sub3En}
                <br />
                <br />
              </div>
            )}
          </p>
        ) : (
          ""
        )}
        {sub4 ? (
          <p>
            {language === "de" ? (
              <div>
                {sub4}
                <br />
                <br />
              </div>
            ) : (
              <div>
                {sub4En}
                <br />
                <br />
              </div>
            )}
          </p>
        ) : (
          ""
        )}
        {sub5 ? (
          <p>
            {language === "de" ? (
              <div>
                {sub5}
                <br />
                <br />
              </div>
            ) : (
              <div>
                {sub5En}
                <br />
                <br />
              </div>
            )}
          </p>
        ) : (
          ""
        )}
        {sub6 ? (
          <p>
            {language === "de" ? (
              <div>
                {sub6}
                <br />
                <br />
              </div>
            ) : (
              <div>
                {sub6En}
                <br />
                <br />
              </div>
            )}
          </p>
        ) : (
          ""
        )}
        {sub7 ? (
          <p>
            {language === "de" ? (
              <div>
                {sub7}
                <br />
                <br />
              </div>
            ) : (
              <div>
                {sub7En}
                <br />
                <br />
              </div>
            )}
          </p>
        ) : (
          ""
        )}
        {sub8 ? (
          <p>
            {language === "de" ? (
              <div>
                {sub8}
                <br />
                <br />
              </div>
            ) : (
              <div>
                {sub8En}
                <br />
                <br />
              </div>
            )}
          </p>
        ) : (
          ""
        )}
        {sub9 ? (
          <p>
            {language === "de" ? (
              <div>
                {sub9}
                <br />
                <br />
              </div>
            ) : (
              <div>
                {sub9En}
                <br />
                <br />
              </div>
            )}
          </p>
        ) : (
          ""
        )}
        {sub10 ? (
          <p>
            {language === "de" ? (
              <div>
                {sub10}
                <br />
                <br />
              </div>
            ) : (
              <div>
                {sub10En}
                <br />
                <br />
              </div>
            )}
          </p>
        ) : (
          ""
        )}
        {sub11 ? (
          <p>
            {language === "de" ? (
              <div>
                {sub11}
                <br />
                <br />
              </div>
            ) : (
              <div>
                {sub11En}
                <br />
                <br />
              </div>
            )}
          </p>
        ) : (
          ""
        )}
        {sub12 ? (
          <p>
            {language === "de" ? (
              <div>
                {sub12}
                <br />
                <br />
              </div>
            ) : (
              <div>
                {sub12En}
                <br />
                <br />
              </div>
            )}
          </p>
        ) : (
          ""
        )}
        {sub13 ? (
          <p>
            {language === "de" ? (
              <div>
                {sub13}
                <br />
                <br />
              </div>
            ) : (
              <div>
                {sub13En}
                <br />
                <br />
              </div>
            )}
          </p>
        ) : (
          ""
        )}
        {sub14 ? (
          <p>
            {language === "de" ? (
              <div>
                {sub14}
                <br />
                <br />
              </div>
            ) : (
              <div>
                {sub4En}
                <br />
                <br />
              </div>
            )}
          </p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Dropdown;
