import React from "react";
import "./index.scss";
import Footer from "../../components/Footer";
import { useSelector } from "react-redux";
import animationModule from "../../modules/animationModule";
import { useNavigate } from "react-router";

const Pricing = () => {
  let language = useSelector((state) => state.language);
  let curNavigate = useNavigate();
  return (
    <div className="pricingContainer">
      <div className="pricingContainer__box">
        {language === "de" ? (
          <h1 className="pricingContainer__box__title">Preise & Konditionen</h1>
        ) : (
          <h1 className="pricingContainer__box__title">Prices & Conditions</h1>
        )}
        {language === "de" ? (
          <p className="pricingContainer__box__subtitle">
            Nachstehend finden Sie die Informationen über die Preise und
            Konditionen der Smart Practice. Sollten Sie Fragen haben,
            kontaktieren Sie uns gerne über das Support-Formular, wir werden uns
            dann schnellstmöglich bei Ihnen melden.
            <br />
            <br /> Der Preis der Smart Practice richtet sich nach der Art der
            Praxis, sowie der Anzahl der Behandelnden, die die Anwendung nutzen
            möchten. Jeder der Behandelnden bekommt dann einen eigenen Kalender,
            in dem die Termine und Videosprechstunden geplant und angezeigt
            werden können. Um die Administration zu vereinfachen können Sie
            zusätzlich medizinische Fachangestellte registrieren, dies ist für
            die Preisgestaltung allerdings nicht relevant.
            <br />
            <br /> Außerdem haben Sie die Möglichkeit zwischen einer jährlichen
            und einer monatlichen Abrechnung zu wählen. Bei einer jährlichen
            Abrechnung werden wir Ihr Konto im ersten Monat belasten, die
            Kündigungsfrist ist sechs Wochen vor Vertragsende. Bei einer
            monatlichen Abrechnung wird ihr Konto zu Beginn des Monats für den
            aktuellen Monat belastet, die Kündigungsfrist beträgt dann einen
            Monat.
            <br />
            <br /> Die entsprechenden Preise sehen Sie in unten stehender
            Tabelle:
          </p>
        ) : (
          <p className="pricingContainer__box__subtitle">
            Below you will find the information about the prices and conditions
            of the Smart Practice. If you have any questions please contact us
            via the support form, we will get back to you as soon as possible.
            get back to you as soon as possible.
            <br />
            <br /> The price of the Smart Practice is based on the type of
            practice, as well as the number of practitioners who wish to use the
            application. want to use the application. Each of the practitioners
            then receives their own calendar, in which appointments and video
            consultations can be scheduled and displayed. can be scheduled and
            displayed. To simplify the administration you can register
            additional medical assistants, but this is not relevant for pricing.
            not relevant for the pricing, however.
            <br />
            <br /> You also have the option to choose between an annual and
            monthly billing. In the case of an annual we will debit your account
            in the first month, the cancellation Notice period is six weeks
            before the end of the contract. With a monthly billing, your account
            will be debited at the beginning of the month for the current month.
            for the current month, the notice period is then one month. month.
            <br />
            <br />
            You can see the corresponding prices in below Table:
          </p>
        )}
        <div className="pricingContainer__box__table">
          {language === "de" ? (
            <div className="pricingContainer__box__table__columnOne">
              <div></div>
              <p>Einzelpraxis </p>
              <p>Gemeinschaftspraxis pro Professional </p>
              <p>Praxisgemeinschaft pro Professional</p>
              <p>MVZ pro Professional </p>
            </div>
          ) : (
            <div className="pricingContainer__box__table__columnOne">
              <div></div>
              <p>Individual practice </p>
              <p>Joint practice per professional </p>
              <p>Joint practice pro Professional</p>
              <p>MVZ pro Professional </p>
            </div>
          )}
          <div className="pricingContainer__box__table__columnTwo">
            {language === "de" ? (
              <div className="pricingContainer__box__table__columnTwo__inner">
                <p>Monatlich</p>
                <p>pro Monat</p>
              </div>
            ) : (
              <div className="pricingContainer__box__table__columnTwo__inner">
                <p>Monthly</p>
                <p>per Month</p>
              </div>
            )}
            <p>39 € </p>
            <p>39 €</p>
            <p>59 €</p>
            <p>59 € </p>
          </div>
          <div className="pricingContainer__box__table__columnThree">
            {language === "de" ? (
              <div className="pricingContainer__box__table__columnThree__inner">
                <p></p>
                <p>Insgesamt </p>
              </div>
            ) : (
              <div className="pricingContainer__box__table__columnThree__inner">
                <p></p>
                <p>Total </p>
              </div>
            )}
            <p>348 € </p>
            <p>348 €</p>
            <p>588 €</p>
            <p>588 € </p>
          </div>
          <div className="pricingContainer__box__table__columnFour">
            {language === "de" ? (
              <div className="pricingContainer__box__table__columnFour__inner">
                <p>Jährlich</p>
                <p>Preis pro Monat </p>
              </div>
            ) : (
              <div className="pricingContainer__box__table__columnFour__inner">
                <p>Annual</p>
                <p>Price per Month </p>
              </div>
            )}
            <p>29 € </p>
            <p>29 €</p>
            <p>49 €</p>
            <p>49 € </p>
          </div>
        </div>

        <a
          className="pricingContainer__box__button"
          onClick={() => {
            animationModule(
              curNavigate,
              "/register",
              document.querySelector(".routesWrapper")
            );
          }}
        >
          <img src="/Assets/list.png" alt="list" />
          {language === "de" ? <p>ANMELDEN</p> : <p>REGISTER NOW</p>}
        </a>

        <Footer />
      </div>
    </div>
  );
};

export default Pricing;
