import React from "react";
import { useSelector } from "react-redux";
import Footer from "../../components/Footer";

import "./index.scss";

const Product = ({}) => {
  let language = useSelector((state) => state.language);
  return (
    <div className="productContainer">
      {language === "de" ? (
        <div className="productContainer__box">
          <img
            className="productContainer__box__imageOne"
            src="/Assets/man.png"
            alt="man"
          />
          <h1 className="productContainer__box__title">
            Für <span>Gesundheitseinrichtungen </span>
          </h1>
          <p className="productContainer__box__subtitle">
            Smart Practice bietet Ihnen die Möglichkeit, Videosprechstunden zu
            planen, buchbar zu machen und durchzuführen. Der Videoservice ist
            KBV gelistet und TÜV zertifiziert und kann gemäß der Gebührenordnung
            abgerechnet werden, weitere Informationen zu der Abrechnung finden
            Sie hier. Die Anwendung funktioniert sowohl für registrierte
            Patient:innen als auch für Personen, die sich nicht anmelden möchten
            und stattdessen mittels einem einmal Passwort anonymisiert an der
            Videosprechstunde teilnehmen (TAN-Verfahren). Auch
            Gruppenkonsultationen mit mehreren Fachleuten, sowie mehreren
            Patient:innen sind möglich.
            <br /> <br />
            Die Videosprechstunde bietet Ihnen und den Teilnehmenden folgende
            Funktionalitäten:
          </p>
          <div className="productContainer__box__container">
            <div className="productContainer__box__container__inner">
              <img src="/Assets/pic1.png" alt="pic1" />
              <p style={{ marginTop: "33px" }}>Audio</p>
            </div>
            <div className="productContainer__box__container__inner">
              <img src="/Assets/pic2.png" alt="pic2" />
              <p>Video</p>
            </div>
            <div className="productContainer__box__container__inner">
              <img src="/Assets/pic3.png" alt="pic3" />
              <p>Chat</p>
            </div>
            <div className="productContainer__box__container__inner">
              <img src="/Assets/pic4.png" alt="pic4" />
              <p>
                Austausch <br />
                von Dateien
              </p>
            </div>
            <div className="productContainer__box__container__inner">
              <img
                style={{ width: "60px", marginBottom: "10px" }}
                src="/Assets/calendar.svg"
                alt="pic4"
              />
              <p>Terminbuchung</p>
            </div>
          </div>
          <p className="productContainer__box__underSub">
            Darüber hinaus gibt es ein Chat-Modul und ein Modul zum Austausch
            von Dokumenten. In dem Chat können Sie eine bilaterale Kommunikation
            herstellen, aber auch mit Gruppen von Patient:innen oder
            Kolleg:innen und anderem Fachpersonal kommunizieren. Bei dem
            Austausch von Dateien kann sowohl der Gesundheitsdienstleistende als
            auch Patient:innen jeweils Dokumente auf der Plattform hochladen und
            diese dort ablegen. Sollen diese dann mit einer Praxis oder einer
            bestimmten Ansprechperson geteilt werden, kann das Dokument
            entsprechend freigegeben werden.
            <br />
            <br /> Die Anwendung ist direkt über das Web aufrufbar, Sie müssen
            also keine Software installieren, sondern lediglich ein Profil bei
            uns anlegen. Sobald dieses validiert ist können sie die Vorteile der
            Smart Practice vollumfänglich nutzen und Ihren Patient:innen zur
            Verfügung stellen. Informationen zu dem neuen Service können dabei
            auf Ihrer Webseite aber auch in der Praxis ausgelegt werden.
            <br />
            <br />
            Außerdem arbeiten wir kontinuierlich daran, die Anwendung zu
            verbessern, um Sie in Ihrem Alltag besser unterstützen zu können.
            Falls Sie also Anregungen für uns haben, lassen Sie uns das gerne
            über das Supportformular wissen, wir freuen uns darüber!
          </p>
          <img
            className="productContainer__box__imageTwo"
            src="/Assets/woman.png"
            alt="woman"
          />
          <div className="productContainer__box__titleTwo">
            Für <span>Patient:innen </span>
          </div>
          <p className="productContainer__box__subtitleTwo">
            Mit der Smart Practice können Sie lange Wege und volle Wartezimmer
            vermeiden, und stattdessen Ihre Termine online buchen und daran
            teilnehmen. Die Verbindung ist Ende-zu-Ende verschlüsselt und Ihre
            Kommunikation damit sicher, auch wenn Sie sich über ein
            ungesichertes Netzwerk anmelden. <br />
            <br />
            Um Smart Practice vollumfänglich zu nutzen, können Sie sich entweder
            selbst registrieren oder die Anmeldung gemeinsam mit Ihrem
            Gesundheitsdienstleistenden vornehmen. Außerdem gibt es die
            Möglichkeit, auch ohne Anmeldung an einer Videosprechstunde
            teilzunehmen, mittels dem sogenannten TAN-Verfahren. Die Teilnahme
            ist dabei sowohl über das Web als auch als App verfügbar.
            <div className="productContainer__box__li">
              <u> Selbstregistrierung.</u>
              <br />
              <br /> 1. Rufen Sie die folgende Seite auf:
              <a href="https://smartpractice.mdoc.one">
                https://smartpractice.mdoc.one
              </a>
              .
              <br />
              2. Klicken Sie unten auf den Registrieren-Button. <br />
              3. Geben Sie Ihre Daten in die Maske ein und bestätigen Sie die
              Eingaben. <br />
              4. Melden Sie sich mit den zuvor angelegten Daten an. <br />
              5. Bei der erstmaligen Anmeldung werden Sie aufgefordert, die
              Datenschutzbestimmungen sowie die Nutzungsbedingungen zu
              akzeptieren. <br />
              6. Danach werden Sie auf die Startseite weitergeleitet.
              <br />
              <br /> <u>Registrierung durch Gesundheitsdienstleistende:</u>
              <br />
              <br />
              1. Sprechen Sie mit Ihrem Behandelnden über die Registrierung.
              <br />
              2. Gemeinsam können Sie die notwendigen Daten direkt in der
              Plattform ausfüllen, insbesondere werden eine Mailadresse und Ihre
              Kontaktdaten benötigt. <br />
              3. Nachdem das Profil angelegt wurde, können Sie sich auf dieser
              Seite
              <a href="https://smartpractice.mdoc.one/login">
                https://smartpractice.mdoc.one/login
              </a>
              mit den entsprechenden Anmeldedaten (E-Mail oder Benutzername und
              Passwort) anmelden.
              <br />
              <br />
              <u> Teilnahme ohne Registrierung (TAN-Verfahren):</u>
              <br />
              <br />
              1. Ihr Gesundheitsdienstleister benötigt Ihre E-Mail Adresse,
              weitere Angaben wie Vorname und Nachname sind optional.
              <br />
              2. Die Einladungsmail kann in Deutsch und oder Englisch versendet
              werden.
              <br />
              3. Nach Erhalt dieser Mail nutzen Sie den Link in der Mail oder
              diesen Link
              <a href="https://smartpractice.mdoc.one/login">
                https://smartpractice.mdoc.one/login
              </a>
              und klicken Sie dann auf den Button Anmeldung mit TAN. <br />
              4. Geben Sie in dem neuen Feld den TAN ein, den Sie in der Mail
              bekommen haben.
            </div>
          </p>
          <a
            href="https://smartpractice.mdoc.one/login"
            className="productContainer__box__button"
          >
            <img src="/Assets/user.png" alt="arrow" />
            <p>jetzt anmelden</p>
          </a>
        </div>
      ) : (
        <div className="productContainer__box">
          <img
            className="productContainer__box__imageOne"
            src="/Assets/man.png"
            alt="man"
          />
          <h1 className="productContainer__box__title">
            For <span>Medical Practitioners</span>
          </h1>
          <p className="productContainer__box__subtitle">
            Smart Practice offers you the possibility to schedule, book and
            conduct video consultations. This works both for registered patients
            and for people who do not want to register and instead participate
            anonymously in the video consultation using a one-time password.
            Group consultations with several specialists and patients are also
            possible. <br />
            The video consultation offers you and the participants the following
            functionalities:
          </p>
          <div className="productContainer__box__container">
            <div className="productContainer__box__container__inner">
              <img src="/Assets/pic1.png" alt="pic1" />
              <p style={{ marginTop: "33px" }}>Audio</p>
            </div>
            <div className="productContainer__box__container__inner">
              <img src="/Assets/pic2.png" alt="pic2" />
              <p>Video</p>
            </div>
            <div className="productContainer__box__container__inner">
              <img src="/Assets/pic3.png" alt="pic3" />
              <p>Chat</p>
            </div>
            <div className="productContainer__box__container__inner">
              <img src="/Assets/pic4.png" alt="pic4" />
              <p>File exchange</p>
            </div>
          </div>
          <p className="productContainer__box__underSub">
            In addition, we offer a chat module to further simplify and improve
            doctor-patient communication. In the chat you can write to
            individual persons as well as communicate with a certain group, for
            example a team of doctors.
          </p>
          <img
            className="productContainer__box__imageTwo"
            src="/Assets/woman.png"
            alt="woman"
          />
          <div className="productContainer__box__titleTwo">
            For <span>Patients</span>
          </div>
          <p className="productContainer__box__subtitleTwo">
            The Smart Practice offers you the possibility to stay in contact
            with your stay in contact with your therapists at a distance, for
            you free of charge. To participate in the video consultation you
            have three different options:
            <div className="productContainer__box__li">
              <li>You already have an account and want to log in?</li>
              <li>You have received the access data from your practice?</li>
              <li>You have received a one-time password?</li>
            </div>
            Select the method you prefer and follow the steps described there to
            steps described there to log in. If you have any questions please
            have a look at the FAQs or contact your your practice!
          </p>
          <a
            href="https://smartpractice.mdoc.one/login"
            className="productContainer__box__button"
          >
            <img src="/Assets/user.png" alt="arrow" />
            <p>login now</p>
          </a>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Product;
