import React from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import animationModule from "../../modules/animationModule";
import Footer from "../../components/Footer";

const SmartLanding = ({}) => {
  let language = useSelector((state) => state.language);
  let curNavigate = useNavigate();
  return (
    <div className="landingContainer">
      <div className="landingContainer__box">
        <img
          className="landingContainer__box__image"
          src="/Assets/landingPhoto.png"
          alt="landing"
        />

        <div>
          {language === "de" ? (
            <h1 className="landingContainer__box__title">
              Willkommen bei <span> Smart Practice</span>
            </h1>
          ) : (
            <h1 className="landingContainer__box__title">
              Welcome to <span> Smart Practice</span>
            </h1>
          )}
          {language === "de" ? (
            <p className="landingContainer__box__subtitle">
              Ihrer smarten Videosprechstunde, die Gesundheitsdienstleistende
              und Patient:innen miteinander verbindet. Die kontaktlose
              Kommunikation zwischen Patient:innen und Ihren
              Gesundheitsdienstleister:innen ist in der aktuellen Situation
              wichtiger denn je. Sinnvoll eingesetzt können digitale Lösungen
              dem Gesundheitswesen dabei helfen, Menschen zu schützen und
              Ansteckungsrisiken zu verringern. Nutzen Sie unseren Smart Health
              Video Service mit sicherer Ende-zu-Ende Kommunikation und
              vermeiden Sie lange Wege und volle Wartezimmer für Ihren nächsten
              Termin.
            </p>
          ) : (
            <p className="landingContainer__box__subtitle">
              Your smart video consultation that connects healthcare providers
              and patients. Contactless communication between patients and their
              healthcare providers is more important than ever in the current
              situation. Used wisely, digital solutions can help healthcare
              protect people and reduce infection risks. Use our Smart Health
              Video service with secure end-to-end communication and avoid long
              distances and full waiting rooms for your next appointment.
            </p>
          )}
        </div>

        <a
          onClick={() => {
            animationModule(
              curNavigate,
              "/product",
              document.querySelector(".routesWrapper")
            );
          }}
          className="landingContainer__box__button"
        >
          {language === "de" ? <p>mehr erfahren</p> : <p>More</p>}
          <img src="/Assets/arrowRight.png" alt="arrow" />
        </a>
        <div className="landingContainer__box__register">
          <div className="landingContainer__box__register__left">
            <img
              className="landingContainer__box__register__logo"
              src="/Assets/man.png"
              alt="man"
            />
            {language === "de" ? (
              <h2>
                Sind Sie
                <br />
                <span> Gesundheitsdientleister:in?</span>
              </h2>
            ) : (
              <h2>
                Are you
                <br />
                <span> a Doctor?</span>
              </h2>
            )}
            {language === "de" ? (
              <p className="landingContainer__box__register__subtitle">
                Sie arbeiten in einer Gesundheitseinrichtung und haben noch kein
                Konto bei Smart Practice? Falls Sie weitere Informationen zu dem
                Produkt haben möchten, schauen Sie sich gerne auf dieser
                Webseite um oder kontaktieren Sie uns direkt über das{" "}
                <a
                  className="landingContainer__box__register__support"
                  onClick={() => {
                    animationModule(
                      curNavigate,
                      "/support",
                      document.querySelector(".routesWrapper")
                    );
                  }}
                >
                  Supportformular!
                </a>{" "}
                Sie sind schon überzeugt? Dann geht’s direkt weiter mit der
                Registrierung.
              </p>
            ) : (
              <p className="landingContainer__box__register__subtitle">
                Do you work in a healthcare facility and don't yet have a Smart
                Practice account yet? If you would like more information about
                the product, feel free to have a look at this website or contact
                us via the support form! Already convinced? Then continue
                directly with the registration.
              </p>
            )}
            <a
              onClick={() => {
                animationModule(
                  curNavigate,
                  "/register",
                  document.querySelector(".routesWrapper")
                );
              }}
              className="landingContainer__box__register__button"
            >
              <img src="/Assets/list.png" alt="list" />
              {language === "de" ? (
                <p>JETZT REGISTRIEREN</p>
              ) : (
                <p>REGISTER NOW</p>
              )}
            </a>
          </div>
          <div className="landingContainer__box__register__divider"></div>
          <div className="landingContainer__box__register__right">
            <img
              className="landingContainer__box__register__logo"
              src="/Assets/woman.png"
              alt="man"
            />
            {language === "de" ? (
              <h2>
                Sind Sie
                <br />
                <span> Patient:in? </span>
              </h2>
            ) : (
              <h2>
                Are you
                <br />
                <span> a Patient? </span>
              </h2>
            )}
            {language === "de" ? (
              <p className="landingContainer__box__register__subtitle">
                Sie möchten unseren Service nutzen, um eine Videosprechstunde zu
                buchen oder daran teilzunehmen? Oder wurden Sie bereits von
                ihrem Gesundheitsdienstleistenden zu einer Sprechstunde
                eingeladen? Dann geht’s für Sie hier entlang, die Nutzung ist
                für Sie vollständig kostenfrei und mit keinem Abonnement
                verbunden.
              </p>
            ) : (
              <p className="landingContainer__box__register__subtitle">
                You would like to use our service to book or participate in a
                video consultation? or participate in one? Or have you already
                been invited by by your health care provider for a consultation?
                invited? Then it's this way for you, the use is completely free
                of charge for you and is not linked to any subscription.
                associated.
              </p>
            )}
            <a
              className="landingContainer__box__register__button"
              href="https://smartpractice.mdoc.one/login"
            >
              <img src="/Assets/list.png" alt="list" />
              {language === "de" ? (
                <p>JETZT REGISTRIREN</p>
              ) : (
                <p>REGISTER NOW</p>
              )}
            </a>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default SmartLanding;
