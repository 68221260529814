import React, { useState, useRef } from "react";
import "./index.scss";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import emailjs from "@emailjs/browser";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SmartRegister = ({}) => {
  let language = useSelector((state) => state.language);

  let optionsTwo = [
    "Baden-Württemberg",
    "Bayern",
    "Berlin",
    "Brandenburg",
    "Bremen",
    "Hamburg",
    "Hessen",
    "Mecklenburg-Vorpommern",
    "Niedersachsen",
    "Nordrhein-Westfalen",
    "Rheinland-Pfalz",
    "Saarland",
    "Sachsen",
    "Sachsen-Anhalt",
    "Schleswig-Holstein",
    "Thüringen",
  ];

  let optionsFour = [
    "Hebamme",
    "Ärztliche Einzelpraxis",
    "Ärztliche Gemeinschaftspraxis",
    "Ärztliche Praxisgemeinschaft",
    "MVZ",
  ];

  let optionsRefTwo = useRef();

  let [openBoxTwo, setOpenBoxTwo] = useState(false);

  let [openBoxFour, setOpenBoxFour] = useState(false);
  let organisationsRef = useRef();
  let fachRef = useRef();
  let firstNameRef = useRef();
  let lastNameRef = useRef();
  let mailRef = useRef();
  let phoneRef = useRef();
  let cheboxRef = useRef();
  let cheboxTwoRef = useRef();
  let adresseRef = useRef();
  let praxisRef = useRef();
  let codeRef = useRef();
  let birthRef = useRef();
  let bundesRef = useRef();
  let wohnRef = useRef();
  let zipRef = useRef();
  let hausRef = useRef();
  let orgmailRef = useRef();
  let radioMonatRef = useRef();
  let radioJahrRef = useRef();
  let form = useRef();
  let [errorMsg, setErrorMsg] = useState();
  let [sendErrorMsg, setSendErrorMsg] = useState();
  let [steps, setSteps] = useState(1);
  let [spinner, setSpinner] = useState();
  let [radioButtonMsg, setRadioButtonMsg] = useState();
  let [active, setActive] = useState(true);
  const [startDate, setStartDate] = useState();
  let dataRef = useRef({});

  const isChecked = () => {
    let checkbox = document.querySelector(".checkbox");
    let checkboxTwo = document.querySelector(".checkboxTwo");
    if (checkbox.checked === false || checkboxTwo.checked === false) {
      setErrorMsg(<p className="error">Bitte beide Kästchen ankreuzen!</p>);
    } else {
      dataRef.current.organisation = organisationsRef.current.value;
      dataRef.current.fach = fachRef.current.value;
      dataRef.current.firstName = firstNameRef.current.value;
      dataRef.current.lastName = lastNameRef.current.value;
      dataRef.current.email = mailRef.current.value;
      dataRef.current.phone = phoneRef.current.value;
      dataRef.current.adresse = adresseRef.current.value;
      dataRef.current.haus = hausRef.current.value;
      dataRef.current.praxis = praxisRef.current.value;
      dataRef.current.orgmail = orgmailRef.current.value;
      dataRef.current.code = codeRef.current.value;
      dataRef.current.birth = startDate.toLocaleDateString();
      dataRef.current.bundesland = bundesRef.current.value;
      dataRef.current.wohn = wohnRef.current.value;
      dataRef.current.zip = zipRef.current.value;
      setSteps(2);
      setErrorMsg("");
    }
  };

  const sendMail = (e) => {
    setSpinner(
      <img
        style={{ width: "35px", height: "35px" }}
        src="/Assets/spinner.svg"
      />
    );
    setActive(false);
    emailjs
      .sendForm(
        "service_pob1at8",
        "template_1nq6gve",
        form.current,
        "N1SgnH4Dgewd8YvDu"
      )
      .then((result) => {
        if (result.text === "OK") {
          setSpinner();
          let box = document.querySelector(
            ".containerSupport__box__steps__four"
          );
          box.style.display = "flex";
          let timer = document.getElementById("timer");
          let startingSec = 3;
          setInterval(() => {
            countdown();
          }, 1000);

          const countdown = () => {
            let seconds = startingSec--;
            if (seconds <= 0) seconds = 0;
            timer.innerHTML = seconds;
            setSteps(3);
          };
          form.current.reset();

          setTimeout(() => {
            switch (dataRef.current.fach) {
              case "Einzelpraxis":
                if (dataRef.current.monat) {
                  window.location.href =
                    "https://buy.stripe.com/7sI4gq51k7Vz5I4004";
                } else if (dataRef.current.jahr) {
                  window.location.href =
                    "https://buy.stripe.com/14k7sC2Tcb7L6M8bIN";
                }
                break;
              case "Gemeinschaftspraxis":
                if (dataRef.current.monat) {
                  window.location.href =
                    "https://buy.stripe.com/eVa6oy79s0t76M83ci";
                } else if (dataRef.current.jahr) {
                  window.location.href =
                    "https://buy.stripe.com/6oE4gqbpI1xb6M86oq";
                }
                break;
              case "MVZ":
                if (dataRef.current.monat) {
                  window.location.href =
                    "https://buy.stripe.com/bIY00a0L48ZDc6seV2";
                } else if (dataRef.current.jahr) {
                  window.location.href =
                    "https://buy.stripe.com/eVa14e3Xg2BfeeA6or";
                }
                break;
              case "Praxisgemeinschaft":
                if (dataRef.current.monat) {
                  window.location.href =
                    "https://buy.stripe.com/00g4gqdxQ5Nr0nK28f";
                } else if (dataRef.current.jahr) {
                  window.location.href =
                    "https://buy.stripe.com/9AQcMW0L43FjgmI145";
                }
                break;
              case "Hebamme":
                window.location.href =
                  "https://buy.stripe.com/5kAfZ865o6RvfiE9AL";
                break;
              default:
                setSendErrorMsg(
                  <p className="error">Ein Fehler is aufgetreten!</p>
                );
                setActive(true);
            }
          }, 3000);
        } else {
          setSendErrorMsg(<p className="error">Ein Fehler is aufgetreten!</p>);
          setActive(true);
        }
      })

      .catch((e) => {
        console.log("ERR", e, e.response);
      });
  };

  return (
    <div className="containerSupport">
      <div className="containerSupport__box">
        {language === "de" ? (
          <h1 className="containerSupport__box__title">Registrieren</h1>
        ) : (
          <h1 className="containerSupport__box__title">Register</h1>
        )}

        <div className="containerSupport__box__steps">
          <div className="containerSupport__box__steps__flex">
            <div className="containerSupport__box__steps__one"></div>
            {language === "de" ? <p>Registrierung</p> : <p>Register</p>}
          </div>
          <div className="containerSupport__box__steps__flex">
            <div
              className={
                steps === 2 || steps === 3
                  ? "containerSupport__box__steps__two containerSupport__box__steps__active"
                  : "containerSupport__box__steps__two"
              }
            ></div>
            {language === "de" ? <p>Weitere Daten</p> : <p>More data</p>}
          </div>

          <div className="containerSupport__box__steps__flex">
            <div
              className={
                steps === 3
                  ? "containerSupport__box__steps__two containerSupport__box__steps__active"
                  : "containerSupport__box__steps__two"
              }
            ></div>
            {language === "de" ? <p>Zahlung</p> : <p>Payment</p>}
          </div>
        </div>
        <div className="containerSupport__box__divider"></div>
        <div
          className="containerSupport__box__first"
          style={{
            display: steps === 1 ? "flex" : "none",
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              isChecked();
            }}
            ref={form}
          >
            <div className="containerSupport__box__name">
              <div className="containerSupport__box__name__inner containerSupport__box__relative">
                {language === "de" ? (
                  <label htmlFor="">AZH Kundennummer</label>
                ) : (
                  <label htmlFor="">Action code</label>
                )}

                <input
                  ref={codeRef}
                  type="text"
                  name="code"
                  pattern="\d*"
                  maxLength="6"
                />
              </div>
            </div>
            <p className="personal">Persönliche Daten: </p>
            <div className="containerSupport__box__name">
              <div className="containerSupport__box__name__inner containerSupport__box__relative">
                {language === "de" ? (
                  <label htmlFor="">
                    Vorname <span>*</span>
                  </label>
                ) : (
                  <label htmlFor="">
                    First Name <span>*</span>
                  </label>
                )}

                <input
                  ref={firstNameRef}
                  required
                  type="text"
                  name="firstName"
                />
              </div>
              <div className="containerSupport__box__name__inner containerSupport__box__relative">
                {language === "de" ? (
                  <label htmlFor="">
                    Nachname <span>*</span>
                  </label>
                ) : (
                  <label htmlFor="">
                    Last Name <span>*</span>
                  </label>
                )}

                <input ref={lastNameRef} required type="text" name="lastName" />
              </div>
            </div>

            <div className="containerSupport__box__name">
              <div className="containerSupport__box__name__inner containerSupport__box__relative">
                {language === "de" ? (
                  <label htmlFor="">
                    Geburtsdatum <span>*</span>
                  </label>
                ) : (
                  <label htmlFor="">
                    Date of Birth <span>*</span>
                  </label>
                )}

                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="dd.MM.yyyy"
                  ref={birthRef}
                  required
                  name="birth"
                />
                {/* <input ref={birthRef} required type="date" name="birth" /> */}
              </div>
            </div>
            <div className="containerSupport__box__name ">
              <div className="containerSupport__box__name__inner containerSupport__box__relative">
                <label>
                  E-Mail <span>*</span>
                </label>
                <input ref={mailRef} required type="email" name="email" />
              </div>
              <div className="containerSupport__box__name__inner containerSupport__box__relative">
                {language === "de" ? (
                  <label>
                    Telefon <span>*</span>
                  </label>
                ) : (
                  <label>
                    Phone <span>*</span>
                  </label>
                )}

                <input ref={phoneRef} required type="number" name="tel" />
              </div>
            </div>
            <p className="personal">Organisation:</p>

            <div className="containerSupport__box__form">
              <div className="containerSupport__box__organisation">
                {language === "de" ? (
                  <label htmlFor="">Name der Organisation</label>
                ) : (
                  <label htmlFor="">Name der Organisation</label>
                )}

                <input ref={organisationsRef} type="text" name="organisation" />
              </div>
              <div className="containerSupport__box__relative">
                {language === "de" ? (
                  <label htmlFor="">
                    Fachbereich<span>*</span>
                  </label>
                ) : (
                  <label htmlFor="">
                    Faculty<span>*</span>
                  </label>
                )}
                <div
                  onClick={() => {
                    setOpenBoxFour(!openBoxFour);
                  }}
                  className="containerSupport__box__dropdownFour"
                >
                  <input
                    name="fach"
                    required
                    ref={fachRef}
                    className="containerSupport__box__dropdownFour__paragraph typeInput"
                  ></input>
                  {openBoxFour ? (
                    <img
                      className="containerSupport__box__dropdownFour__imgAround"
                      src="/Assets/arrowDown.png"
                      alt=""
                    />
                  ) : (
                    <img src="/Assets/arrowDown.png" alt="" />
                  )}
                </div>
                <div
                  ref={optionsRefTwo}
                  className={
                    openBoxFour
                      ? "containerSupport__box__dropdownFour__options containerSupport__box__dropdownFour__options--active"
                      : "containerSupport__box__dropdownFour__options"
                  }
                >
                  {optionsFour.map((itemFour) => {
                    return (
                      <p
                        className="containerSupport__box__dropdownFour__options__inner"
                        onClick={() => {
                          let textFour = document.querySelector(
                            ".containerSupport__box__dropdownFour__paragraph"
                          );
                          textFour.value = itemFour;

                          if (openBoxFour === true) {
                            setOpenBoxFour(false);
                          }
                        }}
                      >
                        {itemFour}
                      </p>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="containerSupport__box__name">
              <div className="containerSupport__box__name__inner containerSupport__box__relative">
                {language === "de" ? (
                  <label htmlFor="">
                    Straße <span>*</span>
                  </label>
                ) : (
                  <label htmlFor="">
                    Street <span>*</span>
                  </label>
                )}
                <input ref={adresseRef} required type="text" name="strasse" />
              </div>
              <div className="containerSupport__box__name__inner containerSupport__box__relative">
                {language === "de" ? (
                  <label>
                    Hausnummer <span>*</span>
                  </label>
                ) : (
                  <label>
                    Number <span>*</span>
                  </label>
                )}
                <input ref={hausRef} required type="number" name="haus" />
              </div>
            </div>

            <div className="containerSupport__box__name ">
              <div className="containerSupport__box__name__inner containerSupport__box__relative">
                {language === "de" ? (
                  <label>
                    PLZ <span>*</span>
                  </label>
                ) : (
                  <label>
                    Zip code <span>*</span>
                  </label>
                )}
                <input ref={zipRef} required type="number" name="zip" />
              </div>
              <div className="containerSupport__box__name__inner containerSupport__box__relative">
                {language === "de" ? (
                  <label>
                    Wohnort <span>*</span>
                  </label>
                ) : (
                  <label>
                    Residence <span>*</span>
                  </label>
                )}
                <input ref={wohnRef} required type="text" name="wohnort" />
              </div>
            </div>

            <div className="containerSupport__box__form">
              <div className="containerSupport__box__relative">
                {language === "de" ? (
                  <label htmlFor="">
                    Bundesland <span>*</span>
                  </label>
                ) : (
                  <label htmlFor="">
                    State <span>*</span>
                  </label>
                )}

                <div
                  onClick={() => {
                    setOpenBoxTwo(!openBoxTwo);
                  }}
                  className="containerSupport__box__dropdownTwo"
                >
                  <input
                    name="bundesland"
                    ref={bundesRef}
                    required
                    className="containerSupport__box__dropdownTwo__paragraph typeInput"
                  ></input>
                  {openBoxTwo ? (
                    <img
                      className="containerSupport__box__dropdownTwo__imgAround"
                      src="/Assets/arrowDown.png"
                      alt=""
                    />
                  ) : (
                    <img src="/Assets/arrowDown.png" alt="" />
                  )}
                </div>
                <div
                  ref={optionsRefTwo}
                  className={
                    openBoxTwo
                      ? "containerSupport__box__dropdownTwo__options containerSupport__box__dropdownTwo__options--active"
                      : "containerSupport__box__dropdownTwo__options"
                  }
                >
                  {optionsTwo.map((itemTwo) => {
                    return (
                      <p
                        className="containerSupport__box__dropdownTwo__options__inner"
                        onClick={() => {
                          let textTwo = document.querySelector(
                            ".containerSupport__box__dropdownTwo__paragraph"
                          );
                          textTwo.value = itemTwo;

                          if (openBoxTwo === true) {
                            setOpenBoxTwo(false);
                          }
                        }}
                      >
                        {itemTwo}
                      </p>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="containerSupport__box__name">
              <div className="containerSupport__box__name__inner containerSupport__box__relative">
                {language === "de" ? (
                  <label htmlFor="">Webseite Organisation</label>
                ) : (
                  <label htmlFor="">Practice website</label>
                )}

                <input ref={praxisRef} type="text" name="website" />
              </div>
              <div className="containerSupport__box__name__inner containerSupport__box__relative">
                {language === "de" ? (
                  <label htmlFor="">
                    E-Mail Organisation <span></span>
                  </label>
                ) : (
                  <label htmlFor="">
                    Mail Organisation <span></span>
                  </label>
                )}
                <input ref={orgmailRef} type="email" name="orgmail" />
              </div>
            </div>
            <p className="containerSupport__pflicht">
              <span>*</span> Pflichtfelder
            </p>

            <div className="containerSupport__box__checkBox">
              <input ref={cheboxRef} type="checkbox" className="checkbox" />
              {language === "de" ? (
                <p>
                  Hiermit erteile ich meine Zustimmung zu den{" "}
                  <a target="_blank" href="#/data">
                    Datenschutzbestimmungen
                  </a>
                  .
                </p>
              ) : (
                <p>
                  I hereby give my consent to the{" "}
                  <a href="#/data" target="_blank">
                    Privacy policy
                  </a>
                  .
                </p>
              )}
            </div>

            <div className="containerSupport__box__checkBox">
              <input
                ref={cheboxTwoRef}
                type="checkbox"
                className="checkboxTwo"
              />
              {language === "de" ? (
                <p>
                  Ich stimme den{" "}
                  <a href="#/agb" target="_blank">
                    AGB
                  </a>{" "}
                  zu.
                </p>
              ) : (
                <p>
                  I agree with the{" "}
                  <a href="#/agb" target="_blank">
                    AGB
                  </a>{" "}
                  .
                </p>
              )}
            </div>

            {errorMsg}
            <button className="landingContainer__box__button">
              {language === "de" ? (
                <p
                  onClick={() => {
                    // parseData();
                  }}
                >
                  weiter
                </p>
              ) : (
                <p
                  onClick={() => {
                    // parseData();
                  }}
                >
                  continue
                </p>
              )}

              <img src="/Assets/arrowRight.png" alt="arrow" />
            </button>

            <input
              type="text"
              name="monat"
              hidden
              value={dataRef.current.monat}
            />
            <input
              type="text"
              name="jahr"
              hidden
              value={dataRef.current.jahr}
            />
          </form>
        </div>

        <form
          className="containerSupport__formSecond"
          onSubmit={(e) => {
            e.preventDefault();

            let radioButtonOne = document.querySelector(".radioButtonOne");
            let radioButtonTwo = document.querySelector(".radioButtonTwo");
            if (radioButtonOne.checked || radioButtonTwo.checked) {
              if (radioButtonOne.checked === true) {
                dataRef.current.monat = radioMonatRef.current.value;
                dataRef.current.jahr = "";
              } else {
                dataRef.current.jahr = radioJahrRef.current.value;
                dataRef.current.monat = "";
              }
              setRadioButtonMsg("");
              setSteps(3);
            } else {
              setRadioButtonMsg(
                <p style={{ marginBottom: "30px" }} className="error">
                  Eine Zahlungsmethode auswählen
                </p>
              );
            }
          }}
          style={{
            display: steps === 2 ? "block" : "none",
          }}
        >
          <div className="containerSupport__box__second">
            {language === "de" ? (
              <p className="containerSupport__box__second__subtitle">
                Basierend auf diesen Angaben sind die Kosten für die Smart
                Practice wie folgt:
              </p>
            ) : (
              <p className="containerSupport__box__second__subtitle">
                Based on this information, the cost for the Smart Practice are
                as follows:
              </p>
            )}

            {dataRef.current.fach === "Einzelpraxis" && (
              <>
                <div className="containerSupport__box__second__radio">
                  <input
                    className="radioButtonOne"
                    ref={radioMonatRef}
                    name="money"
                    type="radio"
                    value="39.00 €"
                  />
                  {language === "de" ? (
                    <p className="containerSupport__box__second__subtitle">
                      39.00 € bei monatlicher Zahlung
                    </p>
                  ) : (
                    <p className="containerSupport__box__second__subtitle">
                      39.00 € with monthly payment
                    </p>
                  )}
                </div>
                <div className="containerSupport__box__second__radio">
                  <input
                    className="radioButtonTwo"
                    ref={radioJahrRef}
                    name="money"
                    type="radio"
                    value="348.00 €"
                  />
                  {language === "de" ? (
                    <p className="containerSupport__box__second__subtitle">
                      348.00 € bei jährlicher Zahlung (entspricht 29.00 €
                      monatlich)
                    </p>
                  ) : (
                    <p className="containerSupport__box__second__subtitle">
                      348.00 € for annual payment (equivalent to 29.00 €
                      monthly)
                    </p>
                  )}
                </div>
              </>
            )}

            {dataRef.current.fach === "Gemeinschaftspraxis" && (
              <>
                <div className="containerSupport__box__second__radio">
                  <input
                    className="radioButtonOne"
                    ref={radioMonatRef}
                    name="money"
                    type="radio"
                    value="39.00 €"
                  />
                  {language === "de" ? (
                    <p className="containerSupport__box__second__subtitle">
                      39.00 € bei monatlicher Zahlung
                    </p>
                  ) : (
                    <p className="containerSupport__box__second__subtitle">
                      39.00 € with monthly payment
                    </p>
                  )}
                </div>
                <div className="containerSupport__box__second__radio">
                  <input
                    className="radioButtonTwo"
                    ref={radioJahrRef}
                    name="money"
                    type="radio"
                    value="348.00 €"
                  />
                  {language === "de" ? (
                    <p className="containerSupport__box__second__subtitle">
                      348.00 € bei jährlicher Zahlung (entspricht 29.00 €
                      monatlich)
                    </p>
                  ) : (
                    <p className="containerSupport__box__second__subtitle">
                      348.00 € for annual payment (equivalent to 29.00 €
                      monthly)
                    </p>
                  )}
                </div>
              </>
            )}

            {dataRef.current.fach === "Praxisgemeinschaft" && (
              <>
                <div className="containerSupport__box__second__radio">
                  <input
                    className="radioButtonOne"
                    ref={radioMonatRef}
                    name="money"
                    type="radio"
                    value="59.00 €"
                  />
                  {language === "de" ? (
                    <p className="containerSupport__box__second__subtitle">
                      59.00 € bei monatlicher Zahlung
                    </p>
                  ) : (
                    <p className="containerSupport__box__second__subtitle">
                      59.00 € with monthly payment
                    </p>
                  )}
                </div>
                <div className="containerSupport__box__second__radio">
                  <input
                    className="radioButtonTwo"
                    ref={radioJahrRef}
                    name="money"
                    type="radio"
                    value="588.00 € "
                  />
                  {language === "de" ? (
                    <p className="containerSupport__box__second__subtitle">
                      588.00 € bei jährlicher Zahlung (entspricht 49.00 €
                      monatlich)
                    </p>
                  ) : (
                    <p className="containerSupport__box__second__subtitle">
                      588.00 € for annual payment (equivalent 49.00 € monthly)
                    </p>
                  )}
                </div>
              </>
            )}

            {dataRef.current.fach === "MVZ" && (
              <>
                <div className="containerSupport__box__second__radio">
                  <input
                    className="radioButtonOne"
                    ref={radioMonatRef}
                    name="money"
                    type="radio"
                    value="59.00 €"
                  />
                  {language === "de" ? (
                    <p className="containerSupport__box__second__subtitle">
                      59.00 € bei monatlicher Zahlung
                    </p>
                  ) : (
                    <p className="containerSupport__box__second__subtitle">
                      59.00 € for monthly payment
                    </p>
                  )}
                </div>
                <div className="containerSupport__box__second__radio">
                  <input
                    className="radioButtonTwo"
                    ref={radioJahrRef}
                    name="money"
                    type="radio"
                    value="588.00 €"
                  />
                  {language === "de" ? (
                    <p className="containerSupport__box__second__subtitle">
                      588.00 € bei jährlicher Zahlung (entspricht 49€ monatlich)
                    </p>
                  ) : (
                    <p className="containerSupport__box__second__subtitle">
                      588.00 € for annual payment (equivalent to 49€ monthly)
                    </p>
                  )}
                </div>
              </>
            )}

            {dataRef.current.fach === "Hebamme" && (
              <>
                <div className="containerSupport__box__second__radio">
                  <input
                    className="radioButtonOne"
                    ref={radioMonatRef}
                    name="money"
                    type="radio"
                    value="€ 11,90 inkl. MwSt."
                    checked
                  />
                  {language === "de" ? (
                    <p className="containerSupport__box__second__subtitle">
                      € 11,90 inkl. MwSt. bei monatlicher Zahlung für einen
                      Nutzer, monatlich kündbar. Berechnung erfolgt nach Ihrem
                      kostenlosen Testzeitraum von 31 Tagen.
                    </p>
                  ) : (
                    <p className="containerSupport__box__second__subtitle">
                      € 11,90 incl. VAT with monthly payment for one user, can
                      be cancelled monthly. Invoicing takes place after your
                      free trial period of 31 days.
                    </p>
                  )}
                </div>
              </>
            )}

            <p style={{ marginTop: "-30px" }}> {radioButtonMsg}</p>

            <div className="containerSupport__box__back">
              <a
                className="containerSupport__box__zuruck"
                onClick={() => {
                  setSteps(1);
                }}
              >
                <img src="/Assets/arrowRight.png" alt="arrow" />
                {language === "de" ? <p> Züruck</p> : <p> Back</p>}
              </a>
              <button
                className="landingContainer__box__button"
                onClick={() => {}}
              >
                {language === "de" ? <p>Weiter</p> : <p>Continue</p>}
                <img src="/Assets/arrowRight.png" alt="arrow" />
              </button>
            </div>
          </div>
        </form>

        <div
          style={{
            display: steps === 3 ? "block" : "none",
          }}
        >
          <div className="containerSupport__box__last">
            {language === "de" ? (
              <h2 className="containerSupport__box__last__angaben">
                Sie haben folgende Daten angegeben:
              </h2>
            ) : (
              <h2 className="containerSupport__box__last__angaben">
                You have entered the following data:
              </h2>
            )}
            <div className="containerSupport__box__last__box">
              <div className="containerSupport__box__last__box__inner">
                <div className="containerSupport__box__last__box__inner__flex">
                  {language === "de" ? (
                    <div className="containerSupport__box__last__box__inner__flex__paragraph">
                      <p>AZH Kundennummer:</p>
                      <p>Vorname:</p>
                      <p>Nachname:</p>
                      <p>Geburtsdatum:</p>
                      <p>Email:</p>
                      <p>Telefon:</p>
                    </div>
                  ) : (
                    <div className="containerSupport__box__last__box__inner__flex__paragraph">
                      <p>Action-Code</p>
                      <p>First name:</p>
                      <p>Surname:</p>
                      <p>Date of Birth:</p>
                      <p>Email:</p>
                      <p>Phone:</p>
                    </div>
                  )}
                  <div className="containerSupport__box__last__box__inner__flex__span">
                    <span>{dataRef.current.code}</span>
                    <span>{dataRef.current.firstName}</span>
                    <span>{dataRef.current.lastName}</span>
                    <span>{dataRef.current.birth}</span>
                    <span>{dataRef.current.email}</span>
                    <span>{dataRef.current.phone}</span>
                  </div>
                </div>
              </div>
              <div className="containerSupport__box__last__box__inner__flex">
                {language === "de" ? (
                  <div className="containerSupport__box__last__box__inner__flex__paragraph">
                    <p>Organisation:</p>
                    <p>Fachbereich:</p>

                    <p>Strasse:</p>
                    <p>Hausnummer:</p>
                    <p>PLZ:</p>
                    <p>Wohnort:</p>
                    <p>Bundesland:</p>
                    <p>Organisation Website:</p>
                    <p>Organisation E-Mail:</p>
                  </div>
                ) : (
                  <div className="containerSupport__box__last__box__inner__flex__paragraph">
                    <p>Organization:</p>
                    <p>Department:</p>
                    <p>Street:</p>
                    <p>Street number:</p>
                    <p>Zip code</p>
                    <p>Resident</p>
                    <p>State:</p>
                    <p>Practice Website:</p>
                    <p>Organisation Mail:</p>
                  </div>
                )}
                <div className="containerSupport__box__last__box__inner__flex__span">
                  <span>{dataRef.current.organisation}</span>
                  <span>{dataRef.current.fach}</span>
                  <span>{dataRef.current.adresse}</span>
                  <span>{dataRef.current.haus}</span>
                  <span>{dataRef.current.zip}</span>
                  <span>{dataRef.current.wohn}</span>
                  <span>{dataRef.current.bundesland}</span>
                  <span>{dataRef.current.praxis}</span>
                  <span>{dataRef.current.orgmail}</span>
                </div>
              </div>
            </div>
            {language === "de" ? (
              <p>
                Zahlung:
                {dataRef.current.monat === "" ? (
                  <span>{dataRef.current.monat}</span>
                ) : (
                  <span> {dataRef.current.monat}</span>
                )}
                {dataRef.current.jahr === "" ? (
                  <span>{dataRef.current.jahr}</span>
                ) : (
                  <span> {dataRef.current.jahr}</span>
                )}
              </p>
            ) : (
              <p>
                To pay:
                {dataRef.current.monat === "" ? (
                  <span>{dataRef.current.monat}</span>
                ) : (
                  <span> {dataRef.current.monat}</span>
                )}
                {dataRef.current.jahr === "" ? (
                  <span>{dataRef.current.jahr}</span>
                ) : (
                  <span> {dataRef.current.jahr}</span>
                )}
              </p>
            )}
            {sendErrorMsg}
          </div>

          <div className="containerSupport__box__back">
            <a
              className="containerSupport__box__zuruck"
              onClick={() => {
                setSteps(2);
              }}
            >
              <img src="/Assets/arrowRight.png" alt="arrow" />
              {language === "de" ? <p>Züruck</p> : <p>Back</p>}
            </a>
            <button
              disabled={active === false}
              className={
                active === true
                  ? "landingContainer__box__button"
                  : "landingContainer__box__button inactive"
              }
              onClick={() => {
                sendMail();
                setActive(false);
              }}
            >
              <p style={{ marginRight: "unset" }}>
                {spinner ? spinner : "Zur Zahlung"}
              </p>
            </button>
            {language === "de" ? (
              <div className="containerSupport__box__steps__four">
                Sie werden weitergeleitet in <span id="timer"> 3</span>...
              </div>
            ) : (
              <div className="containerSupport__box__steps__four">
                You will be forwarded in <span id="timer"> 3</span>...
              </div>
            )}
          </div>
        </div>

        <div className="containerSupport__box__dividerSecond"></div>
      </div>
      <Footer />
    </div>
  );
};

export default SmartRegister;
