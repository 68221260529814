import "./App.css";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Landing from "./routes/Landing";
import Product from "./routes/Product";
import Pricing from "./routes/Pricing";
import Support from "./routes/Support";
import Faq from "./routes/Faq";
import Register from "./routes/Register";
import { useEffect } from "react";
import Impressum from "./routes/Impressum";
import DataProtection from "./routes/Data Protection";
import Agb from "./routes/Agb";

function App() {
  useEffect(() => {
    document.title = "m.Doc Smart Practice";
  }, []);

  return (
    <Router>
      <Header />
      <div className="routesWrapper">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/product" element={<Product />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/support" element={<Support />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/register" element={<Register />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/data" element={<DataProtection />} />
          <Route path="/agb" element={<Agb />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
