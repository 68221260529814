import React, { useState, useRef } from "react";
import "./index.scss";
import Footer from "../../components/Footer";
import { useSelector } from "react-redux";
import axios from "axios";
import { useLocation, useNavigate } from "react-router";
import emailjs from "@emailjs/browser";

const Support = () => {
  let options = ["Herr", "Frau"];
  let optionsTwo = [
    "Kaufanfrage",
    "Generelle Fragen zum Produkt",
    "Technische Probleme",
    "Sonstiges",
  ];
  let itemRef = useRef();
  let itemRefTwo = useRef();
  let optionsRef = useRef();
  let optionsRefTwo = useRef();
  let [openBox, setOpenBox] = useState(false);
  let [openBoxTwo, setOpenBoxTwo] = useState(false);
  let firstNameRef = useRef();
  let lastNameRef = useRef();
  let organisationsNameRef = useRef();
  let mailRef = useRef();
  let phoneRef = useRef();
  let cheboxRef = useRef();
  let areaRef = useRef();
  let [active, setActive] = useState(true);
  let [spinner, setSpinner] = useState();
  let form = useRef();
  let [errorMsg, setErrorMsg] = useState();
  let language = useSelector((state) => state.language);

  const sendMail = (e) => {
    let checkbox = document.querySelector(".checkbox");
    if (checkbox.checked === false) {
      setErrorMsg(<p className="error">Kästchen ankreuzen!</p>);
      setActive(true);
    } else {
      setSpinner(
        <img
          style={{ width: "35px", height: "35px" }}
          src="/Assets/spinner.svg"
        />
      );
      setActive(false);
      emailjs
        .sendForm(
          "service_pob1at8",
          "template_2c0l6gw",
          form.current,
          "N1SgnH4Dgewd8YvDu"
        )
        .then(
          (result) => {
            if (result.text === "OK") {
              setErrorMsg(
                <p className="success">Mail erfolgreich gesendet!</p>
              );
              setSpinner();
              form.current.reset();
            } else {
              setErrorMsg(<p className="error">Ein Fehler is aufgetreten!</p>);
            }
          },
          (error) => {
            setErrorMsg(<p className="error">Ein Fehler is aufgetreten!</p>);
            console.log(error.text);
          }
        );
    }
    // let fd = new FormData();
    // fd.append("titel", itemRef.current.value);
    // fd.append("grund", itemRefTwo.current.value);
    // fd.append("firstName", firstNameRef.current.value);
    // fd.append("lastName", lastNameRef.current.value);
    // fd.append("email", mailRef.current.value);
    // fd.append("phone", phoneRef.current.value);
    // fd.append("organisation", areaRef.current.value);
    // setSpinner(
    //   <img
    //     style={{ width: "40px", height: "40px" }}
    //     src="/Assets/spinner.svg"
    //   />
    // );
    // setActive(false);
    // axios({
    //   method: "POST",
    //   url: `https://mdoc-new.mdoc.one/wp-json/contact-form-7/v1/contact-forms/13438/feedback`,
    //   data: fd,
    // })
    //     .then((response) => {
    //       if (response.data.status === "mail_sent") {
    //         setErrorMsg(<p className="success">Mail erfolgreich gesendet!</p>);
    //         setSpinner();
    //         form.current.reset();
    //       } else {
    //         setErrorMsg(<p className="error">Ein Fehler is aufgetreten!</p>);
    //         console.log(response);
    //       }
    //     })
    //     .catch((e) => {
    //       console.log("ERR", e, e.response);
    //     });
    // }
  };

  return (
    <div className="containerSupport">
      <div className="containerSupport__box">
        {language === "de" ? (
          <h1 className="containerSupport__box__title">Fragen & Support </h1>
        ) : (
          <h1 className="containerSupport__box__title">Questions & Support</h1>
        )}
        {language === "de" ? (
          <p className="containerSupport__box__subtitle">
            Haben Sie noch Fragen oder möchten Sie ein individuelles Angebot
            besprechen? Füllen Sie das untenstehende Kontaktformular aus und
            beschreiben Sie ihr Anliegen kurz, wir werden uns dann
            schnellstmöglich mit Ihnen in Verbindung setzen.
          </p>
        ) : (
          <p className="containerSupport__box__subtitle">
            Do you have any questions or would you like to discuss an individual
            offer? Fill out the contact form below and briefly describe your
            request, we will contact you as soon as possible.
          </p>
        )}
        <div className="containerSupport__box__divider"></div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            sendMail();
          }}
          ref={form}
        >
          <div className="containerSupport__box__form">
            <div className="containerSupport__box__relative">
              {language === "de" ? (
                <label htmlFor="">Anrede</label>
              ) : (
                <label htmlFor="">Anrede</label>
              )}
              <div
                onClick={() => {
                  setOpenBox(!openBox);
                }}
                className="containerSupport__box__dropdown"
              >
                <input
                  ref={itemRef}
                  name="titel"
                  className="containerSupport__box__dropdown__paragraph typeInput"
                ></input>
                {openBox ? (
                  <img
                    className="containerSupport__box__dropdown__imgAround"
                    src="/Assets/arrowDown.png"
                    alt=""
                  />
                ) : (
                  <img src="/Assets/arrowDown.png" alt="" />
                )}
              </div>
              <div
                ref={optionsRef}
                className={
                  openBox
                    ? "containerSupport__box__dropdown__options containerSupport__box__dropdown__options--active"
                    : "containerSupport__box__dropdown__options"
                }
              >
                {options.map((item) => {
                  return (
                    <p
                      className="containerSupport__box__dropdown__options__inner"
                      onClick={() => {
                        let text = document.querySelector(
                          ".containerSupport__box__dropdown__paragraph"
                        );
                        text.value = item;

                        if (openBox === true) {
                          setOpenBox(false);
                        }
                      }}
                    >
                      {item}
                    </p>
                  );
                })}
              </div>
            </div>
            <div className="containerSupport__box__relative">
              {language === "de" ? (
                <label htmlFor="">Grund für die Kontaktaufnahme</label>
              ) : (
                <label htmlFor="">Reason for contact</label>
              )}
              <div
                onClick={() => {
                  setOpenBoxTwo(!openBoxTwo);
                }}
                className="containerSupport__box__dropdownTwo"
              >
                <input
                  name="grund"
                  ref={itemRefTwo}
                  className="containerSupport__box__dropdownTwo__paragraph typeInput"
                ></input>
                {openBoxTwo ? (
                  <img
                    className="containerSupport__box__dropdownTwo__imgAround"
                    src="/Assets/arrowDown.png"
                    alt=""
                  />
                ) : (
                  <img src="/Assets/arrowDown.png" alt="" />
                )}
              </div>
              <div
                ref={optionsRefTwo}
                className={
                  openBoxTwo
                    ? "containerSupport__box__dropdownTwo__options containerSupport__box__dropdownTwo__options--active"
                    : "containerSupport__box__dropdownTwo__options"
                }
              >
                {optionsTwo.map((itemTwo) => {
                  return (
                    <p
                      className="containerSupport__box__dropdownTwo__options__inner"
                      onClick={() => {
                        let textTwo = document.querySelector(
                          ".containerSupport__box__dropdownTwo__paragraph"
                        );
                        textTwo.value = itemTwo;

                        if (openBoxTwo === true) {
                          setOpenBoxTwo(false);
                        }
                      }}
                    >
                      {itemTwo}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="containerSupport__box__name">
            <div className="containerSupport__box__name__inner containerSupport__box__relative">
              {language === "de" ? (
                <label htmlFor="">
                  Vorname <span>*</span>
                </label>
              ) : (
                <label htmlFor="">
                  First Name <span>*</span>{" "}
                </label>
              )}

              <input ref={firstNameRef} required type="text" name="firstName" />
            </div>
            <div className="containerSupport__box__name__inner containerSupport__box__relative">
              {language === "de" ? (
                <label htmlFor="">
                  Nachname <span>*</span>
                </label>
              ) : (
                <label htmlFor="">
                  Last Name <span>*</span>
                </label>
              )}
              <input ref={lastNameRef} required type="text" name="lastName" />
            </div>
          </div>
          <div className="containerSupport__box__name ">
            <div className="containerSupport__box__name__inner containerSupport__box__relative">
              <label>
                E-Mail <span>*</span>
              </label>
              <input ref={mailRef} required type="email" name="email" />
            </div>
            <div className="containerSupport__box__name__inner containerSupport__box__relative">
              {language === "de" ? (
                <label>Telefon</label>
              ) : (
                <label>Telephone</label>
              )}
              <input ref={phoneRef} type="number" name="phone" />
            </div>
          </div>
          <div className="containerSupport__box__name ">
            <div className="containerSupport__box__name__inner containerSupport__box__relative">
              <label>
                Organisationsname <span>*</span>
              </label>
              <input
                ref={organisationsNameRef}
                required
                type="text"
                name="organisationsname"
              />
            </div>
          </div>
          <div className="containerSupport__box__textBox containerSupport__box__relative">
            {language === "de" ? (
              <label>Nähere Beschreibung der Anfrage</label>
            ) : (
              <label>More detailed description of the request</label>
            )}
            <textarea
              ref={areaRef}
              name="organisation"
              id=""
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <div className="containerSupport__box__checkBox">
            <input ref={cheboxRef} type="checkbox" className="checkbox" />
            {language === "de" ? (
              <p>
                Hiermit erteile ich meine Zustimmung zu den{" "}
                <a href="">Datenschutzbestimmungen</a>
              </p>
            ) : (
              <p>
                I hereby give my consent to the <a href="">Privacy policy</a>
              </p>
            )}
          </div>
          {errorMsg}
          <button
            disabled={active === false}
            className={
              active === true
                ? "landingContainer__box__button"
                : "landingContainer__box__button inactive"
            }
          >
            {spinner ? (
              spinner
            ) : (
              <>
                {language === "de" ? <p>absenden</p> : <p>Submit</p>}
                <img src="/Assets/arrowRight.png" alt="arrow" />
              </>
            )}
          </button>
        </form>

        <div className="containerSupport__box__dividerSecond"></div>
        <Footer />
      </div>
    </div>
  );
};

export default Support;
