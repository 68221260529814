import React, { useState } from "react";
import Dropdown from "../../components/Dropdown";
import Footer from "../../components/Footer";
import "./index.scss";
import animationModule from "../../modules/animationModule";
import { useNavigate } from "react-router";

const Faq = () => {
  let curNavigate = useNavigate();
  let praxis = (
    <>
      <b>Gesundheitsdienstleister:innen:</b>
    </>
  );
  let formular = (
    <>
      Sie möchten Ihren Patient:innen auch eine sichere und unkomplizierte
      Videosprechstunde anbieten? Dann können Sie sich direkt auf dieser Seite
      registrieren. Falls Sie noch Fragen haben oder ein spezielles Angebot
      wünschen, können Sie natürlich auch gerne über unser{" "}
      <a
        className="landingContainer__box__register__support"
        onClick={() => {
          animationModule(
            curNavigate,
            "/support",
            document.querySelector(".routesWrapper")
          );
        }}
      >
        {" "}
        Kontaktformular{" "}
      </a>
      Kontakt zu unserem Salesteam aufnehmen.
    </>
  );
  let patient = (
    <>
      <b>Patient:innen:</b>
    </>
  );
  let registrierung = (
    <>
      <i>
        <b>Registrierung durch Praxis:</b>{" "}
      </i>
      Der Behandler kann Ihre Daten in die Plattform der Videosprechstunde
      eintragen und Ihnen so ein Profil anlegen. Hier wird eine Mailadresse, ein
      Benutzername und ein Initialpasswort verlangt, sodass Sie im nächsten
      Schritt eine Registrierbestätigung an diese Mailadresse bekommen. Beim
      erstmaligen anmelden können Sie dann das Initialpasswort verändern.
    </>
  );
  let selbstregistrierung = (
    <>
      <i>
        <b>Selbstregistrierung:</b>
      </i>{" "}
      Nutzen Sie den Link oder den QR-Code um auf die Registrierungsseite für
      Smart Practice zu gelangen. In beiden Fällen gelangen Sie zu einer
      Eingabemaske, in welche Sie die entsprechenden Daten eintragen. Wenn Sie
      dies abgeschlossen haben, bekommen Sie eine E-Mail mit einem Link zum
      Bestätigen der Anmeldung. Klicken Sie auf diesen Link um die Anmeldung zu
      bestätigen und melden Sie sich mit dem zuvor von Ihnen festgelegten
      Benutzernamen und Passwort an.{" "}
    </>
  );
  let teilnahme = (
    <>
      <i>
        <b>Teilnahme mit TAN:</b>
      </i>{" "}
      Sie können auch ohne Registrierung an der Videosprechstunde teilnehmen.
      Hierfür benötigt der Behandelnde lediglich Ihre Mailadresse. An diese
      Mailadresse wird dann ein Einladungslink sowie ein einmaliger Code zur
      Anmeldung geschickt. Klicken Sie auf den Link und wählen Sie auf der
      Login-Seite das Feld „Anmeldung mit TAN“
    </>
  );

  let gesundheit = (
    <>
      <b>Gesundheitsdienstleister:innen: </b>
    </>
  );

  let patienten = (
    <>
      <b>Patient:innen: </b>
    </>
  );

  let desktop = (
    <>
      <b>Desktop-Computer​:</b>
    </>
  );

  let mobile = (
    <>
      <b>Mobile Geräte :</b>
    </>
  );

  let internet = (
    <>
      <b>Unterstützte Internetbrowser​:</b>
    </>
  );

  return (
    <div className="faqContainer">
      <div className="faqContainer__box">
        <h1 className="faqContainer__box__title">FAQ</h1>
        <Dropdown
          title="Wie kann ich mich auf Smart Practice registrieren?"
          sub1={praxis}
          sub2={formular}
          sub3="Kontaktieren Sie unser Sales Team"
          sub5={patient}
          sub6="Um an der Videosprechstunde teilzunehmen haben Sie generell drei Möglichkeiten: 
          "
          sub7={registrierung}
          sub8={selbstregistrierung}
          sub9={teilnahme}
          titleEn="How do I register on Smart Practice?"
          sub1En="Practice/Professionals:"
          sub2En="Would you also like to offer your patients a safe and uncomplicated video consultation? Then you can register directly on this page. If you still have questions or would like a special offer, you are of course also welcome to contact our sales team via our contact form.
          "
          sub3En="Contact our Sales Team"
          sub5En="Patients:"
          sub6En="To participate in video consultation, you generally have three options: 
          "
          sub7En="Registration by practice: The practitioner can enter your data in the video consultation platform and thus create a profile for you. A mail address, a user name and an initial password are required here, so that you will receive a registration confirmation to this mail address in the next step. When you log in for the first time, you can then change the initial password. "
          sub8En="Self-registration: Use the link or the QR code to access the registration page for Smart Practice. In both cases, you will be taken to an input mask in which you enter the relevant data. Once you have completed this, you will receive an email with a link to confirm your registration. Click on this link to confirm registration and log in with the username and password you previously set. "
          sub9En="Participation with TAN: You can also participate in the video consultation without registering. For this, the treating physician only needs your e-mail address. An invitation link and a one-time registration code will then be sent to this e-mail address. Click on the link and select the 'Registration with TAN' field on the login page."
        />
        <Dropdown
          title="Sind meine Daten sicher? "
          sub1="Ihre Daten sind bei uns sicher. Alle Daten werden in Rechenzentren in Deutschland gespeichert, die nach ISO 27001 und ISO 9001 zertifiziert sind. Die Kommunikation ist durchgehend verschlüsselt. Die Inhalte werden von uns weder eingesehen noch gespeichert. Wir nutzen nur Server im Europäischen Wirtschaftsraum (EWR). Alle Metadaten zur Durchführung der Videosprechstunde (wie z.B. Dauer) werden nach spätestens drei Monaten gelöscht und werden nur für die zur Abwicklung der Videosprechstunde notwendigen Abläufe genutzt. Wir geben die Daten nicht weiter."
          titleEn="Is my data safe? "
          sub1En="Your data is safe with us. All data is stored in data centers in Germany that are certified to ISO 27001 and ISO 9001. Communication is encrypted throughout. The contents are neither viewed nor stored by us. We only use servers in the European Economic Area (EEA). All metadata relating to the performance of the video consultation (such as duration) are deleted after three months at the latest and are only used for the processes necessary for handling the video consultation. We do not share the data."
        />
        <Dropdown
          title="Werden meine Daten gespeichert? "
          sub1="Ihre Daten werden nicht lokal auf Ihrem PC bzw. dem mobilen Gerät gespeichert, sie befinden sich mit dem Login auf den sicheren Servern unseres Dienstleisters in Deutschland. Zur Durchführung der Sprechstunde notwendige Metadaten wie beispielsweise die Dauer der Sprechstunde werden nach spätestens drei Monaten gelöscht. "
          sub2="Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung unserer mobilen App Cookies auf Ihrem mobilen Endgerät gespeichert. Bei Cookies handelt es sich um kleine Textdateien, die im Gerätespeicher Ihres mobilen Endgerätes abgelegt und der von Ihnen verwendeten mobilen App zugeordnet gespeichert werden. Durch Cookies können der Stelle, die den Cookie setzt (hier: uns), bestimmte Informationen zufließen. Cookies können keine Programme ausführen oder Viren auf Ihr mobiles Endgerät übertragen. Sie dienen dazu, mobile Apps insgesamt nutzerfreundlicher und effektiver zu machen.  Die notwendigen Cookies und ihre Verwendung können Sie hier nochmals nachlesen. "
          titleEn="Will my data be stored? "
          sub1En="Your data is not stored locally on your PC or mobile device, it is located with the login on the secure servers of our service provider in Germany. Metadata necessary to conduct the consultation, such as the duration of the consultation, will be deleted after three months at the latest. "
          sub2En="In addition to the previously mentioned data, cookies are stored on your mobile end device when you use our mobile app. Cookies are small text files that are stored in the device memory of your mobile device and assigned to the mobile app you are using. Cookies allow certain information to flow to the entity that sets the cookie (here: us). Cookies cannot execute programs or transmit viruses to your mobile device. They serve to make mobile apps more user-friendly and effective overall.  You can read about the necessary cookies and their use again here. "
        />
        <Dropdown
          title="Was kostet mich Smart Practice? "
          sub1={gesundheit}
          sub2="Die Kosten für Gesundheitseinrichtungen richten sich nach der Art der Praxis sowie der Anzahl der Behandelnden. Genauere Informationen zu den Kosten für Smart Practice finden Sie hier. "
          sub3={patienten}
          sub4="Für Patient:innen ist der Service komplett kostenfrei. "
          titleEn="How much will smart practice cost me? "
          sub1En="Practices/Professionals: "
          sub2En="The cost for your practice depends on the type of practice as well as the number of practitioners. More detailed information on Smart Practice costs can be found here. "
          sub3En="Patients"
          sub4En="For patients, the service is completely free of charge. "
        />
        <Dropdown
          title="Muss ich Smart Practice installieren?  "
          sub1="Zur Nutzung der Smart Practice ist keine Installation auf dem PC oder Laptop erforderlich, die Anwendung läuft über den Webbrowser. Für Patient:innen stellen wir die Anwendung außerdem kostenlos als App zur Verfügung, zur Installation und zur Nutzung nutzen Sie bitte den Apple iPhone® über iTunes® App Store und für Android-Geräte den Google PlayStore.  "
          titleEn="Do I need to install smart practice?   "
          sub1En="No installation on the PC or laptop is required to use Smart Practice, the application runs via the web browser. For Patients we also provide the application free of charge as an app, to install and use please use Apple iPhone® via iTunes® App Store and for Android devices Google PlayStore.   "
        />
        <Dropdown
          title="Gibt es technische Anforderungen?   "
          sub1="Zur Nutzung der Videosprechstunde benötigen Sie lediglich einen aktuellen Browser bzw. die App, eine stabile Internetverbindung, sowie ein Audio- und Videogerät. Das bedeutet:  "
          sub2={desktop}
          sub3="- Windows 8.1, oder neuer ​"
          sub4="- macOS 10.13, oder neuer ​"
          sub5="- Linux Ubuntu 14.04, oder neuer​"
          sub6={mobile}
          sub7="- Android 8, oder neuer ​"
          sub8="- iOS 12, oder neuer​"
          sub9={internet}
          sub10="- Chrome 29, oder neuer ​"
          sub11="- Mozilla Firefox 22, oder neuer ​"
          sub12="- Safari für iOS 7.0, oder neuer​"
          sub13="- (spez. SetUp beachten: Safari-mdoc-Einstellungen.pdf)​
          "
          sub14="- Chrome für Android 79, oder neuer​"
          titleEn="Are there any technical requirements?     "
          sub1En="To use video consultation, all you need is an up-to-date browser or the app, a stable internet connection, and an audio and video device. This means "
          sub2En="Desktop-Computer​:"
          sub3En="- Windows 8.1, or newer "
          sub4En="- macOS 10.13, or newer "
          sub5En="- Linux Ubuntu 14.04, or newer​"
          sub6En="Mobile devices :"
          sub7En="- Android 8, or newer ​"
          sub8En="- iOS 12, or newer​"
          sub9En="Supported Internet browsers:"
          sub10En="- Chrome 29, or newer ​"
          sub11En="- Mozilla Firefox 22, or newer ​"
          sub12En="- Safari for iOS 7.0, or newerr​"
          sub13En="- Safari-mdoc-Settings.pdf"
          sub14En="- Chrome for Android 79,or newerr​"
        />
        <Footer />
      </div>
    </div>
  );
};

export default Faq;
